import DtoReferencial from './DtoReferencial'

export default class DtoCulture extends DtoReferencial {
    constructor(object) {
        super(object)
        this.teteRotation = object.teteRotation // Option[Boolean] = None,
        this.legumineuse = object.legumineuse // Option[Boolean] = None,
        this.periodeSemence = object.periodeSemence // Option[Int] = None,
        this.referentiel = object.referentiel // Option[Boolean] = None,
        this.idFamille = object.idFamille // Option[Int] = None,
        this.limitCropRotations = object.limitCropRotations // Option[Boolean] = None,
        this.usermaj = object.usermaj // Option[String] = None,
        this.datemaj = object.datemaj // Option[DateTime] = None
    }
}
