import { Chip, Grid2 } from '@mui/material'
import React from 'react'
import { darkTextColor, mainColor, textColor, veryLightGrey } from '../../../components/styled/theme'
import PropTypes from 'prop-types'

const UsesStepRow = ({ currentStep, nbStep, children, step, setStep, title }) => (
    <Grid2
        container
        size={12}
        className={currentStep === step ? '' : 'clickable'}
        onClick={currentStep === step ? () => {} : () => setStep(step)}
        sx={{
            borderBottom: currentStep === step ? '' :`1px solid ${veryLightGrey}`,
            rowGap: '1vh',
            paddingBottom: '1vh'
        }}
    >
        <Grid2 size={12} container alignItems='center' sx={{ columnGap: '0.5vw' }}>
            <Chip sx={{ backgroundColor: mainColor, color: 'white' }} size='small' label={step}/>
            <Grid2
                sx={{
                    color: currentStep === step ? textColor : darkTextColor,
                    fontSize: currentStep === step ? '22px' : '16px',
                    lineHeight: currentStep === step ? '28px' : '20px',
                    fontWeight: currentStep === step ? 400 : 500,
                }}
            >
                {title} {currentStep === step ? `(${currentStep}/${nbStep})` : null}
            </Grid2>
        </Grid2>
        {children}
    </Grid2>
)

UsesStepRow.propTypes = {
    currentStep: PropTypes.number,
    nbStep: PropTypes.number,
    step: PropTypes.number,
    setStep: PropTypes.func,
    children: PropTypes.node,
    title: PropTypes.string,
}

export default UsesStepRow