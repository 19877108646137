import { Grid2, useTheme } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { mainColor, textColor } from '../../components/styled/theme'
import { Add, Close, Done, EditOutlined, KeyboardArrowLeft, Remove } from '@mui/icons-material'
import i18n from 'simple-react-i18n'
import { compact, template } from 'lodash'
import moment from 'moment'
import { MainButton, TextButton } from '../../components/styled/buttons'
import { LightCard } from '../../components/styled/grid'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import InstallationsAction from '../../referencials/installations/actions/InstallationsAction'
import { getStateLabel, getTypePrel } from '../../../../utils/AgriUtils'
import LoadingCard from '../../components/cards/LoadingCard'
import DtoContact from '../../referencials/dto/DtoContact'
import { formatPhone, getI18nTitleDataLength } from '../../../../utils/StringUtil'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../../referencials/materiels/constants/MaterielConstants'
import AccordeonDesktop from '../../components/AccordeonDesktop'
import PumpCardDecla from '../../components/cards/PumpCardDecla'
import { ReactComponent as PumpLogo } from '../../../../ressources/static/svg/Pump.svg'
import { ReactComponent as CounterLogo } from '../../../../ressources/static/svg/Counter.svg'
import CounterCardDecla from '../../components/cards/CounterCardDecla'
import TankCardDecla from '../../components/cards/TankCardDecla'
import ConsoCardDecla from '../../components/cards/ConsoCardDecla'
import PlannedUsesCardDecla from '../../components/cards/PlannedUsesCardDecla'
import { push } from '@lagunovsky/redux-react-router'
import { InputMUI } from '../../components/styled/inputs'
import useLocalStorage from '../../../../utils/customHooks/useLocalStorage'
import { DECLA_POINTS_ADVANCEMENT, POINT_ADVANCEMENT_DECLARATION } from '../../agri/constants/AgriConstants'
import AgriAction from '../../agri/actions/AgriAction'
import { useParams } from 'react-router'

const PointDetailsDeclaDesktop = () => {
    const {
        installation,
        declaration,
        codesSandre,
        exploitation,
        contacts,
        citiesIndex,
        variousMateriels,
    } = useSelector((store) => ({
        installation: store.InstallationsReducer.installation,
        declaration: store.AgriReducer.declaration,
        codesSandre: store.ReferencialReducer.codesSandre,
        exploitation: store.AgriReducer.exploitation,
        contacts: store.ContactReducer.contacts,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.InstallationsReducer.variousMateriels,
    }), shallowEqual)

    const [pointsAdvancement, setPointsAdvancement] = useLocalStorage(DECLA_POINTS_ADVANCEMENT)

    const [editMode, setEditMode] = useState(false)
    const [pointName, setPointName] = useState(installation?.name)
    const [infosExpanded, setInfosExpanded] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [matExpanded, setMatExpanded] = useState()
    const [retExpanded, setRetExpanded] = useState()

    const date = moment().valueOf()
    const year = moment().year()

    const params = useParams()

    const idInstallation = useMemo(() => params.id, [params])

    const dispatch = useDispatch()

    const theme = useTheme()

    const handleMatChange = (panel) => {
        if (panel === matExpanded) {
            setMatExpanded()
        } else {
            setMatExpanded(panel)
        }
    }

    const handleRetChange = (panel) => {
        if (panel === retExpanded) {
            setRetExpanded()
        } else {
            setRetExpanded(panel)
        }
    }

    const getAdvancement = () => {
        const point = pointsAdvancement.find(a => a.idPoint === Number(idInstallation) && a.idDeclaration === declaration.idDeclaration) || {}
        if (point.status !== POINT_ADVANCEMENT_DECLARATION.ONGOING) {
            const newAdvancements = [
                ...pointsAdvancement.filter(a => a.idPoint !== point.idPoint && a.idDeclaration === declaration.idDeclaration),
                {
                    idDeclaration: declaration.idDeclaration,
                    idPoint: point.idPoint,
                    status: POINT_ADVANCEMENT_DECLARATION.ONGOING
                },
            ]
            setPointsAdvancement(newAdvancements)
        }
        dispatch(InstallationsAction.fetchInstallation(idInstallation)).then(() => {
            setDataLoaded(true)
            setPointName(installation?.name)
        })
    }

    useEffect(() => {
        if (!declaration.idDeclaration) {
            if (!exploitation.idExploitation) {
                dispatch(AgriAction.fetchExploitation()).then(res => {
                    dispatch(AgriAction.fetchDeclarationByExploitationId(res.idExploitation)).then(getAdvancement)
                })
            } else {
                dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then(getAdvancement)
            }
        } else {
            getAdvancement()
        }
    }, [exploitation])

    const linkPoint = useMemo(() => (
        exploitation.link_samplings ? exploitation.link_samplings.find((p) => p.idInstallation === installation.id) : null
    ), [exploitation, installation])

    const updatedTanks = useMemo(() => (
        linkPoint?.link_sampleTanks?.map(tank => {
            const newTank = declaration.link_declarationInstallation.find(decla => decla.idInstallation === Number(idInstallation))
                ?.link_sampleTanks?.find(declaTank => declaTank.idTank === tank.idTank)
            if (newTank) {
                return { ...newTank, id: tank.id }
            }
            return tank
        })
    ), [declaration, linkPoint])

    const contact = useMemo(() => contacts.find(c => c.id === installation?.ownerCode) || new DtoContact({}), [contacts, installation])

    const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === linkPoint?.idInstallation && (!m.situationEndDate || (m.situationEndDate > date)) && (!m.situationDate || (m.situationDate < date))) || []
    const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (!m.situationEndDate || (m.situationEndDate > date)) && (!m.situationDate || (m.situationDate < date))) || []
    const nbTanks = linkPoint?.link_sampleTanks?.length || 0
    const updatedMats = useMemo(() => declaration.idDeclaration ? [ ...declaration.link_pumps, ...declaration.link_counters ] : [], [declaration])
    const dataMats = [...pointPumps, ...pointCounters].map(p => {
        const mat = variousMateriels.find(m => m.id === p.idVarious)
        const formatedMat = mat.pump ? { ...mat, ...mat.pump } : { ...mat, ...mat.counter }
        const newMat = updatedMats.find(m => mat.id === m.id)
        if (newMat) {
            return { ...formatedMat, ...newMat }
        }
        return formatedMat
    }).filter(mat => !mat?.administrator || (mat?.administrator === exploitation?.operatorCode))
    const nbMats = dataMats.length || 0


    const associatedResources = useMemo(() => {
        const res = linkPoint ? linkPoint?.link_samples[0] : {}
        return {
            ...res,
            managementCode: installation.managementCode,
            subManagementCode: installation.subManagementCode,
        }
    }, [linkPoint])

    const getNature = () => {
        const typePrel = getTypePrel(associatedResources.sampleType)
        const codeFind = codesSandre.find((c) => c.field === typePrel && c.code === associatedResources.sampleNature)
        return codeFind ? codeFind.name : '-'
    }

    const getStatus = () => (
        declaration?.link_declarationInstallation?.find(instal => installation.id === instal.idInstallation)?.stateCode ?? 2
    )

    const savePointName = () => {
        if (editMode) {
            dispatch(InstallationsAction.updateInstallation({ ...installation, name: pointName })).then(() => {
                dispatch(InstallationsAction.fetchInstallation(idInstallation)).then(() => {
                    dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)).then(() => {
                        setEditMode(!editMode)
                    })
                })
            })
        } else {
            setEditMode(!editMode)
        }
    }

    if (!dataLoaded) {
        return <LoadingCard />
    }

    return (
        <Grid2
            container
            size={12}
            alignContent='start'
            justifyContent='space-between'
            sx={{
                maxHeight: '100%',
                overflowY: 'hidden',
            }}
        >
            <Grid2 container size={12} alignItems='center' justifyContent='space-between' color={textColor}>
                <Grid2 container size='auto'>
                    <Grid2
                        sx={{ paddingRight: theme.spacing(3) }}
                        className='clickable'
                        onClick={() => dispatch(push('/declaration'))}
                    >
                        <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                    </Grid2>
                    <Grid2 sx={{ fontSize: '22px', lineHeight: '28px' }} >
                        {i18n.backToPointsList}
                    </Grid2>
                </Grid2>
                <MainButton
                    noFullWidth
                    sx={{ width: 'auto', margin: '0' }}
                    onClick={() => dispatch(push(`/declaration/point/${idInstallation}/usages`))}
                >
                    {i18n.next}
                </MainButton>
            </Grid2>
            <Grid2
                container
                sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.15px',
                    color: mainColor,
                    paddingTop: theme.spacing(3),
                }}
            >
                {template(i18n.pleaseCheckGivenInfosForEachPoints)({ year })}
            </Grid2>
            <Grid2
                container
                size={12}
                justifyContent={'space-between'}
                sx={{
                    overflow: 'hidden',
                    height: '100%',
                    paddingTop: theme.spacing(3),
                }}
            >
                <Grid2
                    container
                    size={3.375}
                    alignContent='flex-start'
                    sx={{
                        overflowY: 'auto',
                        height: 'calc(100% - 5.5rem)',
                    }}
                >
                    <Grid2 container>
                        <LightCard
                            container
                            size={12}
                            direction='column'
                            sx={{
                                padding: theme.spacing(3)
                            }}
                        >
                            <Grid2 container justifyContent='space-between'>
                                <Grid2 fontSize={22} className='bold' >{i18n.descriptive}</Grid2>
                                {!editMode ?
                                    (<EditOutlined
                                        className='clickable'
                                        onClick={() => savePointName()}
                                    />)
                                    :
                                    (
                                        <Grid2>
                                            <Close className='clickable' onClick={() => setEditMode(false)}/>
                                            <Done className='clickable' onClick={() => savePointName()} />
                                        </Grid2>
                                    )
                                }
                            </Grid2>
                            <Grid2 container direction='column' sx={{ paddingTop: theme.spacing(2) }}>
                                <Grid2 container justifyContent='space-between' alignItems='center'>
                                    <Grid2>{i18n.name}</Grid2>
                                    {!editMode ?
                                        (<Grid2 className='bold'>{pointName || '-'}</Grid2>)
                                        :
                                        (<InputMUI
                                            noFullWidth
                                            value={pointName}
                                            onKeyDown={(e) => e.key === 'Enter' ? savePointName() : null}
                                            onChange={(e) => setPointName(e.target.value)}
                                            style={{ width: 'auto' }}
                                        />)
                                    }
                                </Grid2>
                                <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(0.5) }}>
                                    <Grid2>{i18n.code}</Grid2>
                                    <Grid2 className='bold'>{installation?.code || '-'}</Grid2>
                                </Grid2>
                                <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(0.5) }}>
                                    <Grid2>{i18n.status}</Grid2>
                                    <Grid2 className='bold'>{getStateLabel(getStatus())}</Grid2>
                                </Grid2>
                                <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(0.5) }}>
                                    <Grid2>{i18n.nature}</Grid2>
                                    <Grid2 className='bold'>{getNature()}</Grid2>
                                </Grid2>
                                {
                                    infosExpanded ?
                                        (
                                            <>
                                                <Grid2 container justifyContent='space-between'>
                                                    <Grid2>{i18n.lieuDit}</Grid2>
                                                    <Grid2 className='bold'>{installation?.location || '-'}</Grid2>
                                                </Grid2>
                                                <Grid2 container justifyContent='space-between'>
                                                    <Grid2>{i18n.codeParcelle}</Grid2>
                                                    <Grid2 className='bold'>{installation?.link_geo?.length ? `${installation.link_geo[0].parcel} ${installation.link_geo[0].section}` : '-'}</Grid2>
                                                </Grid2>
                                                <Grid2 container justifyContent='space-between'>
                                                    <Grid2>{i18n.x}</Grid2>
                                                    <Grid2 className='bold'>{installation?.x || '-'}</Grid2>
                                                </Grid2>
                                                <Grid2 container justifyContent='space-between'>
                                                    <Grid2>{i18n.y}</Grid2>
                                                    <Grid2 className='bold'>{installation?.y || '-'}</Grid2>
                                                </Grid2>
                                                <Grid2 fontSize={22} className='bold' sx={{ padding: `${theme.spacing(2)} 0` }}>{i18n.owner}</Grid2>
                                                <Grid2 container justifyContent='space-between'>
                                                    <Grid2>{i18n.name}</Grid2>
                                                    <Grid2 className='bold'>{contact?.name || '-'}</Grid2>
                                                </Grid2>
                                                <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(0.5) }}>
                                                    <Grid2>{i18n.address}</Grid2>
                                                    <Grid2 className='bold'>
                                                        {
                                                            !contact?.road && !contact?.postalCode && !citiesIndex[contact?.cityCode]?.name ?
                                                                '-'
                                                                :
                                                                contact?.road ? `${contact?.road}` : ''}{contact?.postalCode ? `, ${contact?.postalCode}` : ''}{citiesIndex[contact?.cityCode]?.name ? `, ${citiesIndex[contact?.cityCode]?.name}` : ''
                                                        }
                                                    </Grid2>
                                                </Grid2>
                                                <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(0.5) }}>
                                                    <Grid2>{i18n.phoneTel}</Grid2>
                                                    <Grid2 className='bold'>{formatPhone(contact?.phoneTel) || '-'}</Grid2>
                                                </Grid2>
                                                <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(0.5) }}>
                                                    <Grid2>{i18n.phoneMobile}</Grid2>
                                                    <Grid2 className='bold'>{formatPhone(contact?.mobile) || '-'}</Grid2>
                                                </Grid2>
                                            </>
                                        )
                                        :
                                        null
                                }
                            </Grid2>
                            {
                                infosExpanded ?
                                    (
                                        <Grid2 container justifyContent='flex-end'>
                                            <TextButton
                                                noFullWidth
                                                startIcon={<Remove />}
                                                sx={{ width: 'auto' }}
                                                onClick={() => setInfosExpanded(false)}
                                            >
                                                {i18n.showLess}
                                            </TextButton>
                                        </Grid2>
                                    )
                                    :
                                    (
                                        <Grid2 container justifyContent='flex-end'>
                                            <TextButton
                                                noFullWidth
                                                startIcon={<Add />}
                                                sx={{ width: 'auto' }}
                                                onClick={() => setInfosExpanded(true)}
                                            >
                                                {i18n.showMore}
                                            </TextButton>
                                        </Grid2>
                                    )
                            }
                        </LightCard>
                        <LightCard
                            container
                            size={12}
                            sx={{
                                padding: `${theme.spacing(3)} ${theme.spacing(3)} 0`,
                                marginTop: theme.spacing(2)
                            }}
                        >
                            <Grid2 fontSize={22} className='bold' >
                                {`${getI18nTitleDataLength(i18n.materiel, i18n.materiels, nbMats)} (${nbMats})`}
                            </Grid2>
                            <Grid2 size ={12}>
                                {
                                    compact(dataMats.map((mat, i) => {
                                        if (mat?.pump) {
                                            return (
                                                <AccordeonDesktop
                                                    expanded={matExpanded === i ?? false}
                                                    onChange={() => handleMatChange(i)}
                                                    sx={{ width: '100%' }}
                                                    title={
                                                        <Grid2
                                                            sx={{
                                                                fontSize: '18px',
                                                                lineHeight: '20px',
                                                                letterSpacing: '0.25px',
                                                                fontWeight: 400,
                                                            }}
                                                        >
                                                            <PumpLogo /> {i18n.pump} {mat.reference}
                                                        </Grid2>}
                                                >
                                                    <PumpCardDecla
                                                        pump={mat}
                                                        noLightCard
                                                    />
                                                </AccordeonDesktop>
                                            )
                                        }
                                        return (
                                            <AccordeonDesktop
                                                expanded={matExpanded === i ?? false}
                                                onChange={() => handleMatChange(i)}
                                                sx={{ width: '100%' }}
                                                title={
                                                    <Grid2
                                                        sx={{
                                                            fontSize: '18px',
                                                            lineHeight: '20px',
                                                            letterSpacing: '0.25px',
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        <CounterLogo /> {i18n.counter} {mat?.reference}
                                                    </Grid2>
                                                }
                                            >
                                                <CounterCardDecla
                                                    counter={mat}
                                                    noLightCard
                                                />
                                            </AccordeonDesktop>
                                        )
                                    }))
                                }
                            </Grid2>
                        </LightCard>
                        <LightCard
                            container
                            size={12}
                            sx={{
                                padding: !nbTanks ? theme.spacing(3) : `${theme.spacing(3)} ${theme.spacing(3)} 0`,
                                marginTop: theme.spacing(2)
                            }}
                        >
                            <Grid2 fontSize={22} className='bold' >
                                {`${getI18nTitleDataLength(i18n.waterRetention, i18n.waterRetentions, nbTanks)} (${nbTanks})`}
                            </Grid2>
                            <Grid2 size={12}>
                                {updatedTanks?.map((retenue, i) => (
                                    <AccordeonDesktop expanded={retExpanded === i ?? false}
                                        onChange={() => handleRetChange(i)}
                                        sx={{ width: '100%' }}
                                        title={
                                            <Grid2
                                                sx={{
                                                    fontSize: '18px',
                                                    lineHeight: '20px',
                                                    letterSpacing: '0.25px',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {i18n.waterRetention} {retenue.idTank}
                                            </Grid2>}
                                    >
                                        <TankCardDecla
                                            retenue={retenue}
                                            noLightCard
                                        />
                                    </AccordeonDesktop>
                                ))}
                            </Grid2>
                        </LightCard>
                    </Grid2>
                </Grid2>
                <Grid2 container size={8.375} alignContent='flex-start' sx={{ overflowY: 'auto', height: 'calc(100% - 5.5rem)' }}>
                    <ConsoCardDecla />
                    <PlannedUsesCardDecla sx={{ marginTop: theme.spacing(3) }} />
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

export default PointDetailsDeclaDesktop