export default class DtoContact {
    constructor(object) {
        this.id = object.id // Int,
        this.codeIdentifiant = object.codeIdentifiant
        this.name = object.name // Option[String] = None,
        this.road = object.road || object.address // Option[String]
        this.address = object.address || object.road // Option[String]
        this.addressComplement = object.additionalAddress // Option[String] = None,
        this.postalCode = object.postalCode || object.postalBox // Option[String] = None,
        this.cityCode = object.cityCode // Option[String] = None,
        this.phoneTel = object.phoneTel // Option[String] = None,
        this.fax = object.fax // Option[String] = None,
        this.bankCode = object.bankCode // Option[String] = None,
        this.desktopTel = object.desktopTel // Option[String] = None,
        this.mobile = object.mobile // Option[String] = None,
        this.email = object.email // Option[String] = None,
        this.socialReason = object.socialReason // Option[String] = None,
        this.title = object.title // Option[String] = None,
        this.phoneTelSecond = object.phoneTelSecond // Option[String] = None,
        this.comment = object.comment // Option[String] = None,
        this.cedexCode = object.cedexCode // Option[Double] = None,
        this.contributor = object.contributor // Option[Int] = None,
        this.birthdate = object.birthdate // Option[DateTime] = None,
        this.contactFunction = object.contactFunction // Option[Long] = None,
        this.noNewsLetterDate = object.noNewsLetterDate // Option[DateTime] = None,
        this.noNewLetterLogin = object.noNewLetterLogin // Option[String] = None,
        this.cedex = object.cedex // Option[String] = None,
        this.civility = object.civility // Option[Long] = None
    }
}
