/* eslint-disable camelcase */
import DtoInstallationSample from '../../../agri/dto/DtoInstallationSample'
import DtoInstallationCartographyData from './DtoInstallationCartographyData'

export default class DtoInstallation {
    constructor(obj) {
        this.id = obj.id
        this.number = obj.id
        this.code = obj.code
        this.designation = obj.designation
        this.name = obj.name
        this.typeName = 'installation'
        this.installationType = obj.installationType
        this.descriptive = obj.descriptive
        this.creationDate = obj.creationDate
        this.endDate = obj.endDate
        this.location = obj.location
        this.x = obj.x
        this.y = obj.y
        this.altitude = obj.altitude
        this.projection = obj.projection
        this.mnemonic = obj.mnemonic
        this.townCode = obj.townCode
        this.declarationTownCode = obj.declarationTownCode
        this.previsionalVisitNumber = obj.previsionalVisitNumber
        this.address = obj.address
        this.comments = obj.comments
        this.declarationDate = obj.declarationDate
        this.declarationNumber = obj.declarationNumber
        this.closeComment = obj.closeComment
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.confidential = obj.confidential
        this.executionModeCode = obj.executionModeCode
        this.stateCode = obj.stateCode
        this.ownerCode = obj.ownerCode
        this.dataOrigin = obj.dataOrigin
        this.exploitationCode = obj.exploitationCode
        this.jobExecutionId = obj.jobExecutionId
        this.status = obj.status
        this.statusLogin = obj.statusLogin
        this.statusDate = obj.statusDate

        this.city = obj.city

        this.effectiveReceiptDate = obj.effectiveReceiptDate
        this.prodDate = obj.prodDate

        this.managementCode = obj.managementCode
        this.subManagementCode = obj.subManagementCode

        this.link_geo = obj.link_geo && obj.link_geo.length ? obj.link_geo.map((c) => new DtoInstallationCartographyData(c)) : []
        this.link_sampleCharacteristics = obj.link_sampleCharacteristics ? obj.link_sampleCharacteristics.map(c => new DtoInstallationSample(c)) : []
    }
}