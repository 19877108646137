/* eslint-disable camelcase */
export default class DtoManagementUnit {
    constructor(obj) {
        this.managementCode = obj.managementCode
        this.id = obj.id
        this.name = obj.name
        this.descriptive = obj.descriptive
        this.supervisorCode = obj.supervisorCode
        this.operatorCode = obj.operatorCode
        this.parent = obj.parent
        this.lowWaterStartDate = obj.lowWaterStartDate
        this.lowWaterEndDate = obj.lowWaterEndDate
        this.annualBreakdown = obj.annualBreakdown
        this.monthlyBreakdown = obj.monthlyBreakdown
        this.lowWaterVentilation = obj.lowWaterVentilation
        this.lowWaterOutVentilation = obj.lowWaterOutVentilation
        this.x = obj.x
        this.y = obj.y
        this.labelWithCode = `${obj.id || ''} - ${obj.name || ''}`
        this.codeWithLabel = `[${obj.id || ''}] ${obj.name || ''}`
        this.link_aup = obj.link_aup ? obj.link_aup : []
        this.linkStations = obj.linkStations ? obj.linkStations : []
    }
}
