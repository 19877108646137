/* eslint-disable no-fallthrough */
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DtoUsageDeclaration from '../../../agri/dto/enquete/DtoUsageDeclaration'
import { DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from '../../../components/styled/dialog'
import { Checkbox, DialogActions, FormControlLabel, Grid2, Radio, RadioGroup } from '@mui/material'
import { lightGrey, mainColor, textColor } from '../../../components/styled/theme'
import { USAGES_CATEGORY } from '../../../agri/constants/AgriConstants'
import { lowerCase, orderBy, round } from 'lodash'
import { MainButton, OrangeButton, TextButton } from '../../../components/styled/buttons'
import { InputRow } from '../../../components/styled/inputs'
import { getSettingInt } from '../../../../../utils/FormUtils'
import useSandreList from '../../../../../utils/customHooks/useSandreList'
import { formatMilliers, getI18nTitleDataLength, getSandreLabel } from '../../../../../utils/StringUtil'
import UsesStepRow from '../steps/UsesStepRow'
import SimpleSelect from '../../../components/forms/SimpleSelect'
import AgriAction from '../../../agri/actions/AgriAction'
import { ArrowCircleRightOutlined } from '@mui/icons-material'
import { hasValue } from '../../../../../utils/NumberUtil'

const USAGE_TYPE_STEP = 1
const USAGE_IRRIG_STEP = {
    CULTURE_FAMILY_STEP: 2,
    IRRIGATION_TYPE_STEP: 3,
    IRRIGATION_GROUND_STEP: 4,
    POINT_SOLICITATION_STEP: 5,
    REQUEST_STEP: 6,
}
const USAGE_SIMPLE_IRRIG_STEP = {
    REQUEST_SIMPLIFIED_CULT_STEP: 4,
}
const USAGE_NON_IRRIG_NO_VOLUME_STEP = {
    USING_PERIOD_WITHOUT_VOLUME_STEP: 2,
}
const USAGE_NON_IRRIG_STEP = {
    VOLUME_STEP: 2,
    USING_PERIOD_STEP: 3,
}

const disableClass = {
    '& .MuiInputBase-root .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.87)',
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)', // for Chrome
        borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
    {
        borderColor: 'rgba(0, 0, 0, 0.25)',
    },
    '& .MuiInputLabel-root.Mui-disabled': {
        color: '#00000099',
    },
}

const ModalUsage = ({ usage, open, update, onClose = () => {}, onSave = () => {}, countersId, currentYear, defaultCategory, isReal }) => {
    const {
        codesSandre,
        usagesCategories,
        culturesFamilies,
        cultures,
        modesIrrigations,
        applicationSettings,
        survey,
        exploitation,
        installation,
        declaration,
    } = useSelector((store) => ({
        codesSandre: store.ReferencialReducer.codesSandre,
        usagesCategories: store.ReferencialReducer.usagesCategories,
        culturesFamilies: store.ReferencialReducer.culturesFamilies,
        cultures: store.ReferencialReducer.cultures,
        modesIrrigations: store.AgriReducer.modesIrrigations,
        applicationSettings: store.HomeReducer.applicationSettings,
        survey: store.AgriReducer.survey,
        exploitation: store.AgriReducer.exploitation,
        installation: store.InstallationsReducer.installation,
        declaration: store.AgriReducer.declaration,
    }), shallowEqual)

    const [step, setStep] = useState(USAGE_TYPE_STEP)
    const volumesMonths = usage?.volumesMonths?.split(';') || []
    const [newUsage, setNewUsage] = useState(usage ?
        new DtoUsageDeclaration({
            ...usage,
            idFamille: cultures.find(c => c.id == usage?.idCulture)?.idFamille,
            month01: volumesMonths[0],
            month02: volumesMonths[1],
            month03: volumesMonths[2],
            month04: volumesMonths[3],
            month05: volumesMonths[4],
            month06: volumesMonths[5],
            month07: volumesMonths[6],
            month08: volumesMonths[7],
            month09: volumesMonths[8],
            month10: volumesMonths[9],
            month11: volumesMonths[10],
            month12: volumesMonths[11],
        })
        :
        new DtoUsageDeclaration({ comment: '', typeIrrigation: null })
    )
    const [usageCategory, setUsageCategory] = useState(usagesCategories.find(c => c.idUsage === newUsage.idUsage)?.category || defaultCategory)
    const [volumeCalculated, setVolumeCalculated] = useState()
    const [ajustEstim, setAjustEstim] = useState()

    const bottom = useRef()

    useEffect(() => {
        bottom.current?.scrollIntoView({ behavior: 'smooth' })
    }, [step])

    const { isCulture, isSimplifiedCulture, isCalculAuto, isCheckCoherence, isVolumeRealEntry, showVolumeIfCounter } = useMemo(() => {
        const usageFind = usagesCategories.find(c => c.idUsage === newUsage.idUsage)
        return {
            isCulture: usageFind?.isCulture,
            isSimplifiedCulture: usageFind?.simplifiedCulture,
            isCalculAuto: usageFind?.calculAuto,
            isCheckCoherence: usageFind?.checkCoherence,
            isVolumeRealEntry: usageFind?.volumeRealEntry,
            showVolumeIfCounter: usageFind?.showVolumeIfCounter,
        }
    }, [newUsage])

    const buttonDisabled = useMemo(() => {
        switch (step) {
            case USAGE_TYPE_STEP :
                return !newUsage.idUsage
            case USAGE_IRRIG_STEP.CULTURE_FAMILY_STEP :
                if (isCulture || isSimplifiedCulture) {
                    return !newUsage.idCulture
                }
            case USAGE_NON_IRRIG_STEP.VOLUME_STEP :
                if (isVolumeRealEntry && !isCulture && !isSimplifiedCulture) {
                    return !newUsage.requestedYearVolume
                }
            case USAGE_NON_IRRIG_NO_VOLUME_STEP.USING_PERIOD_WITHOUT_VOLUME_STEP :
                if (!isVolumeRealEntry && !isCulture && !isSimplifiedCulture) {
                    return !newUsage.link_periodes?.length
                }
            case USAGE_IRRIG_STEP.IRRIGATION_TYPE_STEP :
                if (isCulture) {
                    return !newUsage.irrigationMode
                }
                if (isSimplifiedCulture) {
                    return !newUsage.irrigationMode || !newUsage.area
                }
            case USAGE_NON_IRRIG_STEP.USING_PERIOD_STEP :
                if (isVolumeRealEntry && !isCulture && !isSimplifiedCulture) {
                    return !newUsage.link_periodes?.length
                }
            case USAGE_IRRIG_STEP.IRRIGATION_GROUND_STEP :
                if (isCulture) {
                    return !newUsage.groundDepth
                }
            case USAGE_SIMPLE_IRRIG_STEP.REQUEST_SIMPLIFIED_CULT_STEP :
                if (isSimplifiedCulture) {
                    if (isReal) {
                        return (isVolumeRealEntry && !newUsage.requestedYearVolume) || !newUsage.area
                    }
                    return !newUsage.requestedYearVolume || !newUsage.area
                }
            case USAGE_IRRIG_STEP.POINT_SOLICITATION_STEP :
                if (isCulture) {
                    return !newUsage.requestPrelevement
                }
            case USAGE_IRRIG_STEP.REQUEST_STEP :
                if (isCulture) {
                    return (!newUsage.requestedYearVolume) && (!isReal || isVolumeRealEntry)
                }
            default : return false
        }
    }, [step, newUsage])

    const nbStep = useMemo(() => {
        if (isCulture) {
            return 6
        }
        if (isSimplifiedCulture) {
            return 4
        }
        if (isReal && isVolumeRealEntry) {
            return 4
        }
        return 3
    }, [isCulture, isSimplifiedCulture, isReal, isVolumeRealEntry])

    const usagesPeriodes = useSandreList('USAGES.PERIODES')
    const usagesPeriodesDetails = useSandreList('USAGES.PERIODES_DETAILS')
    const irrigationCategories = useSandreList('IRRIGATION.CATEGORIES')
    const usagesTypesSols = useSandreList('USAGES.TYPES_SOLS')
    const usagesCategorie = useSandreList('USAGES.CATEGORIE')
    const usagesProfondeurSols = useSandreList('USAGES.PROFONDEUR_SOLS')
    const usagesType = useSandreList('USAGES.TYPES')

    const usageSaisonnal = useMemo(() => (newUsage.link_periodes.find((p) => p.idPeriode === usagesPeriodes.find(c => c.name === 'Saisons')?.code)), [newUsage])

    const saisons = useMemo(() => {
        return !usageSaisonnal ?
            ''
            :
            usagesPeriodesDetails.map(p => {
                const foundPeriode = newUsage.link_periodes.find(s => s.idNbPeriode === p.code)
                return foundPeriode ? p.name : null
            })
                .filter(Boolean)
                .join(', ')
    }, [usageSaisonnal, codesSandre, newUsage.link_periodes])

    const handleSeasonChange = (season) => {
        let newUsagePeriodes = []
        if (newUsage.link_periodes.find((p) => p.idNbPeriode === Number(season))) {
            newUsagePeriodes = [
                ...newUsage.link_periodes,
            ]
            newUsagePeriodes = newUsagePeriodes.filter((p) => p.idNbPeriode !== null && p.idNbPeriode !== Number(season))
        } else {
            newUsagePeriodes = [
                ...newUsage.link_periodes,
                {
                    ...newUsage.link_periodes[0],
                    idNbPeriode: Number(season),
                    idSurvey: newUsage.idSurvey || null,
                    idInstallation: newUsage.idInstallation || installation.id,
                    idUsage: newUsage.idProvisionalUsage || -1,
                },
            ]
            newUsagePeriodes = newUsagePeriodes.filter((p) => p.idNbPeriode !== null)
        }
        if (!newUsagePeriodes.length) {
            newUsagePeriodes.push({
                idPeriode: 2,
                idNbPeriode: null,
                idSurvey: newUsage.idSurvey || null,
                idInstallation: newUsage.idInstallation || installation.id,
                idUsage: newUsage.idProvisionalUsage || -1,
            })
        }
        setNewUsage({ ...newUsage, link_periodes: newUsagePeriodes })
    }

    const usages = useMemo(() => {
        const declaInstall = declaration?.link_declarationInstallation?.find(i => i.idInstallation === installation.id)
        return (
            currentYear ?
                declaInstall?.link_usagesCurrent
                :
                declaInstall?.link_usages
        )
    }, [declaration, installation])

    const saveUsage = () => {
        const formattedUsage = {
            ...newUsage,
            area: newUsage.idCulture ? round(newUsage.area, 4) : null,
            nbRotation: newUsage.idCulture ? newUsage.nbRotation : null,
            requestPrelevement: newUsage.idCulture ? newUsage.requestPrelevement : null,
            currentYear: !!(newUsage.currentYear || currentYear),
            idExploitation: exploitation.idExploitation,
            idInstallation: installation.id,
            idSurvey: declaration.idSurvey,
            idProvisionalUsage: newUsage.idProvisionalUsage || -1,
            requestedYear: survey.year,
            volumesMonths: `${newUsage.month01 || ''};${newUsage.month02 || ''};${newUsage.month03 || ''};${newUsage.month04 || ''};${newUsage.month05 || ''};${newUsage.month06 || ''};${newUsage.month07 || ''};${newUsage.month08 || ''};${newUsage.month09 || ''};${newUsage.month10 || ''};${newUsage.month11 || ''};${newUsage.month12 || ''}`,
        }
        const newUsages = newUsage.idProvisionalUsage ?
            usages.filter(
                (u) => u.idProvisionalUsage !== formattedUsage.idProvisionalUsage,
            )
            :
            usages
        onClose()
        onSave([
            ...newUsages,
            formattedUsage,
        ])
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if (((step >= USAGE_IRRIG_STEP.REQUEST_STEP && isCulture) || (step >= USAGE_SIMPLE_IRRIG_STEP.REQUEST_SIMPLIFIED_CULT_STEP && isSimplifiedCulture)) && !isReal && (isCalculAuto || isCheckCoherence)) {
            dispatch(AgriAction.calculVolumeUsage({
                usage: {
                    ...newUsage,
                    area: round(newUsage.area, 4),
                    idInstallation: installation.id,
                    requestedYear: survey.year,
                },
                uge: installation.subManagementCode || installation.managementCode,
            })).then(result => {
                const volume = isNaN(parseInt(result)) ? 0 : result
                if (hasValue(newUsage.requestedYearVolume)) {
                    setNewUsage(prev => ({
                        ...prev,
                        adjustedVolume: newUsage.requestedYearVolume !== volume,
                    }))
                } else {
                    setNewUsage(prev => ({ ...prev, requestedYearVolume: isCheckCoherence ? prev.requestedYearVolume : volume, adjustedVolume: false }))
                }
                setVolumeCalculated(volume)
                setAjustEstim(false)
            })
        }
    }, [step, isCulture, isSimplifiedCulture, isCalculAuto, isCheckCoherence])

    return (
        <DialogMUI
            open={open}
            keepMounted
            onClose={() => {
                onClose()
            }}
        >
            <DialogTitleMUIv2
                onClick={() => {
                    onClose()
                }}>
                {update ? i18n.changeUsage : i18n.newUse}
            </DialogTitleMUIv2>
            <DialogContentMUIDesktop
                style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    rowGap: '2vh'
                }}
            >
                <Grid2 container size={12} spacing={2}>
                    {/* Premier étape ajout/modification d'un usage */}
                    <UsesStepRow step={USAGE_TYPE_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.usageType}>
                        {
                            step <= USAGE_TYPE_STEP ?
                                <RadioGroup
                                    row
                                    name='catUsage'
                                    onChange={(e) => setUsageCategory(Number(e.target.value))}
                                    sx={{
                                        color: textColor
                                    }}
                                >
                                    {
                                        usagesCategorie.map(cat => (
                                            <FormControlLabel
                                                checked={usageCategory === cat.code}
                                                value={cat.code}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: textColor,
                                                            '&.Mui-checked': {
                                                                color: textColor,
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={cat.name}
                                            />
                                        ))
                                    }
                                </RadioGroup>
                                :
                                <Grid2 sx={{ color: lightGrey, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>{i18n.usage} {usageCategory === USAGES_CATEGORY.AGRI ? i18n.agri : i18n.nonAgri} : {usagesCategories.find(c => c.idUsage === newUsage.idUsage)?.description} </Grid2>
                        }
                    </UsesStepRow>
                    {step === USAGE_TYPE_STEP &&
                    <Grid2 size={12} container>
                        {!!usageCategory && (
                            <Grid2
                                size={12}
                                sx={{
                                    color: textColor,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontWeight: 500,
                                }}
                            >
                                {i18n.usages} {usageCategory === USAGES_CATEGORY.AGRI ? i18n.agri : i18n.nonAgri}
                            </Grid2>
                        )}
                        <RadioGroup
                            column
                            name='idUsage'
                            onChange={(e) => setNewUsage({ ...newUsage, idUsage: Number(e.target.value) })}
                            sx={{
                                color: textColor
                            }}
                        >
                            {
                                orderBy(usagesCategories.filter(c => c.category === usageCategory && (!usages.find(u => u.idUsage === c.idUsage) || c.multipleEntry || (c.idUsage === newUsage.idUsage && update))), ['order'])
                                    .map(use => (
                                        <FormControlLabel
                                            checked={newUsage.idUsage === use.idUsage}
                                            value={use.idUsage}
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: textColor,
                                                        '&.Mui-checked': {
                                                            color: textColor,
                                                        },
                                                    }}
                                                />
                                            }
                                            label={use.description}
                                        />
                                    ))
                            }
                        </RadioGroup>
                    </Grid2>
                    }
                    {/* Deuxième étape ajout/modification d'un usage : irrigation/irrigation simplifiée */}
                    {
                        step >= USAGE_IRRIG_STEP.CULTURE_FAMILY_STEP && (isCulture || isSimplifiedCulture) &&
                        <UsesStepRow step={USAGE_IRRIG_STEP.CULTURE_FAMILY_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.culturesFamilies}>
                            {
                                step === USAGE_IRRIG_STEP.CULTURE_FAMILY_STEP ?
                                    <>
                                        <SimpleSelect
                                            fullWidth
                                            noNullValue
                                            label={i18n.culturesFamily}
                                            id={i18n.culturesFamily}
                                            value={newUsage.idFamille}
                                            options={culturesFamilies}
                                            keyValue={'id'}
                                            onChange={(id) => setNewUsage({ ...newUsage, idFamille: Number(id) })}
                                        />
                                        {
                                            newUsage.idFamille &&
                                                <SimpleSelect
                                                    fullWidth
                                                    noNullValue
                                                    label={i18n.culture}
                                                    id={i18n.culture}
                                                    value={newUsage.idCulture}
                                                    options={cultures.filter(c => c.idFamille === newUsage.idFamille)}
                                                    keyValue={'id'}
                                                    onChange={(id) => setNewUsage({ ...newUsage, idCulture: Number(id) })}
                                                />
                                        }
                                    </>
                                    :
                                    <Grid2 sx={{ color: lightGrey, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>{culturesFamilies.find(f => f.id === newUsage.idFamille).name} : {cultures.find(c => c.id === newUsage.idCulture).name} </Grid2>
                            }
                        </UsesStepRow>
                    }
                    {/* Deuxième étape ajout/modification d'un usage : usage différent d'irrigation & saisie d'index réel activée */}
                    {
                        step >= USAGE_NON_IRRIG_STEP.VOLUME_STEP && isVolumeRealEntry && !isCulture && !isSimplifiedCulture &&
                        <UsesStepRow step={USAGE_NON_IRRIG_STEP.VOLUME_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.requestedVolume}>
                            {
                                step === USAGE_NON_IRRIG_STEP.VOLUME_STEP ?
                                    <>
                                        <InputRow
                                            type='Number'
                                            label={i18n.volumeYearlyWithUnit}
                                            value={newUsage.requestedYearVolume || ''}
                                            onChange={(e) => setNewUsage({
                                                ...newUsage,
                                                requestedYearVolume: Number(e.target.value) ? (Number(e.target.value) <= 0 ? 0 : Number(e.target.value)) : 0
                                            })}
                                            inputProps={{ min: 0 }}
                                        />
                                        <InputRow
                                            type='Number'
                                            label={i18n.lowWaterVolume}
                                            value={newUsage.lowWaterVolume}
                                            onChange={(e) => setNewUsage({
                                                ...newUsage,
                                                lowWaterVolume: Number(e.target.value) ? (Number(e.target.value) <= 0 ? 0 : Number(e.target.value)) : 0
                                            })}
                                            inputProps={{ min: 0 }}
                                        />
                                    </>
                                    :
                                    <Grid2 sx={{ color: lightGrey, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>{newUsage.requestedYearVolume} {i18n.m3} {i18n.askeds} {i18n.including} {newUsage.lowWaterVolume} {i18n.m3} {i18n.atLowWater}</Grid2>
                            }
                        </UsesStepRow>
                    }
                    {/* Deuxième étape ajout/modification d'un usage : usage différent d'irrigation & saisie d'index réel désactivée */}
                    {
                        step >= USAGE_NON_IRRIG_NO_VOLUME_STEP.USING_PERIOD_WITHOUT_VOLUME_STEP && !isVolumeRealEntry && !isCulture && !isSimplifiedCulture &&
                        <UsesStepRow step={USAGE_NON_IRRIG_NO_VOLUME_STEP.USING_PERIOD_WITHOUT_VOLUME_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.usePeriod}>
                            {
                                step === USAGE_NON_IRRIG_NO_VOLUME_STEP.USING_PERIOD_WITHOUT_VOLUME_STEP ?
                                    <>
                                        <Grid2
                                            size={12}
                                        >
                                            <RadioGroup
                                                row
                                                name='period'
                                                onChange={(event) =>
                                                    event.target.value === usagesPeriodes.find(c => c.name === 'Saisons')?.code ?
                                                        setNewUsage({
                                                            ...newUsage,
                                                            link_periodes: newUsage.link_periodes.map(period =>
                                                                (period.idPeriode = Number(event.target.value))
                                                            )
                                                        })
                                                        :
                                                        setNewUsage({
                                                            ...newUsage,
                                                            link_periodes: [{
                                                                ...newUsage.link_periodes[0],
                                                                idPeriode: Number(event.target.value),
                                                                idNbPeriode: null,
                                                                idSurvey: newUsage.idSurvey || null,
                                                                idInstallation: newUsage.idInstallation || installation.id,
                                                                idUsage: newUsage.idProvisionalUsage || -1,
                                                            }]
                                                        })
                                                }
                                            >
                                                {
                                                    usagesPeriodes.map(period => (
                                                        <FormControlLabel
                                                            sx={{
                                                                color: textColor,
                                                            }}
                                                            checked={period.code === newUsage.link_periodes[0]?.idPeriode ?? null}
                                                            value={period.code}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        color: textColor,
                                                                        '&.Mui-checked': {
                                                                            color: textColor,
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={period.name}
                                                        />
                                                    ))
                                                }
                                            </RadioGroup>
                                        </Grid2>
                                        {
                                            usageSaisonnal ?
                                                usagesPeriodesDetails.map((season) => (
                                                    <FormControlLabel
                                                        sx={{
                                                            color: textColor,
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                sx={{
                                                                    color: textColor,
                                                                    '&.Mui-checked': {
                                                                        color: textColor,
                                                                    },
                                                                }}
                                                                value={season.code}
                                                                checked={newUsage.link_periodes.some(period => period.idNbPeriode === season.code)}
                                                                onChange={(e) => handleSeasonChange(e.target.value)}
                                                            />
                                                        }
                                                        label={season.name}
                                                    />
                                                ))
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <Grid2 sx={{ color: lightGrey, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>{i18n.usage} {usageSaisonnal ? i18n.seasonal : i18n.yearlyNoMaj} {!!saisons.length && ` (${saisons})`}</Grid2>
                            }
                        </UsesStepRow>
                    }
                    {/* troisième étape ajout/modification d'un usage : usage différent d'irrigation & saisie d'index réel activée */}
                    {
                        step >= USAGE_NON_IRRIG_STEP.USING_PERIOD_STEP && isVolumeRealEntry && !isCulture && !isSimplifiedCulture &&
                        <UsesStepRow step={USAGE_NON_IRRIG_STEP.USING_PERIOD_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.usePeriod}>
                            {step === USAGE_NON_IRRIG_STEP.USING_PERIOD_STEP ? (
                                <>
                                    <Grid2 size={12}>
                                        <RadioGroup
                                            row
                                            name='period'
                                            onChange={(event) =>
                                                event.target.value === usagesPeriodes.find(c => c.name === 'Saisons')?.code ?
                                                    setNewUsage({
                                                        ...newUsage,
                                                        link_periodes: newUsage.link_periodes.map(period =>
                                                            (period.idPeriode = Number(event.target.value))
                                                        )
                                                    })
                                                    :
                                                    setNewUsage({
                                                        ...newUsage,
                                                        link_periodes: [{
                                                            ...newUsage.link_periodes[0],
                                                            idPeriode: Number(event.target.value),
                                                            idNbPeriode: null,
                                                            idSurvey: newUsage.idSurvey || null,
                                                            idInstallation: newUsage.idInstallation || installation.id,
                                                            idUsage: newUsage.idProvisionalUsage || -1,
                                                        }]
                                                    })
                                            }
                                        >
                                            {
                                                usagesPeriodes.map(period => (
                                                    <FormControlLabel
                                                        sx={{
                                                            color: textColor,
                                                        }}
                                                        checked={period.code === newUsage.link_periodes[0]?.idPeriode ?? null}
                                                        value={period.code}
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    color: textColor,
                                                                    '&.Mui-checked': {
                                                                        color: textColor,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={period.name}
                                                    />
                                                ))
                                            }
                                        </RadioGroup>
                                    </Grid2>
                                    {
                                        usageSaisonnal ?
                                            usagesPeriodesDetails.map((season) => (
                                                <FormControlLabel
                                                    sx={{
                                                        color: textColor,
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            sx={{
                                                                color: textColor,
                                                                '&.Mui-checked': {
                                                                    color: textColor,
                                                                },
                                                            }}
                                                            value={season.code}
                                                            checked={newUsage.link_periodes.some(period => period.idNbPeriode === season.code)}
                                                            onChange={(e) => handleSeasonChange(e.target.value)}
                                                        />
                                                    }
                                                    label={season.name}
                                                />
                                            ))
                                            :
                                            null
                                    }
                                </>
                            ) : <Grid2 sx={{ color: lightGrey, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>{i18n.usage} {usageSaisonnal ? i18n.seasonal : i18n.yearlyNoMaj} {!!saisons.length && ` (${saisons})`}</Grid2>
                            }
                        </UsesStepRow>
                    }
                    {/* Troisième étape ajout/modification d'un usage : irrigation/irrigation simplifiée */}
                    {
                        step >= USAGE_IRRIG_STEP.IRRIGATION_TYPE_STEP && (isCulture || isSimplifiedCulture) &&
                        <UsesStepRow step={USAGE_IRRIG_STEP.IRRIGATION_TYPE_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.mainTypeIrrigation}>
                            {
                                step === USAGE_IRRIG_STEP.IRRIGATION_TYPE_STEP ? (
                                    <>
                                        <SimpleSelect
                                            fullWidth
                                            noNullValue
                                            label={i18n.mainTypeIrrigation}
                                            id={i18n.mainTypeIrrigation}
                                            value={newUsage.irrigationType}
                                            options={irrigationCategories}
                                            keyValue={'code'}
                                            onChange={(code) => setNewUsage({ ...newUsage, irrigationType: Number(code) })}
                                        />
                                        {newUsage.irrigationType &&
                                            <SimpleSelect
                                                fullWidth
                                                noNullValue
                                                label={i18n.modeIrrigation}
                                                id={i18n.modeIrrigation}
                                                value={newUsage.irrigationMode}
                                                options={modesIrrigations.filter(m => m.categoryId === newUsage.irrigationType)}
                                                keyValue={'id'}
                                                onChange={(id) => setNewUsage({ ...newUsage, irrigationMode: Number(id) })}
                                            />
                                        }
                                        {newUsage.irrigationType && newUsage.irrigationMode && isSimplifiedCulture && (
                                            <InputRow
                                                type='Number'
                                                label={i18n.surface}
                                                value={newUsage.area ?? null}
                                                onChange={(e) => setNewUsage({ ...newUsage, area: Number(e.target.value) })}
                                                InputProps={{ inputProps: { min: 0 } }}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <Grid2 sx={{ color: lightGrey, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>
                                        {getSandreLabel(codesSandre, 'IRRIGATION.CATEGORIES', newUsage.irrigationType)} {i18n.by} {modesIrrigations.find(m => m.id === newUsage.irrigationMode).name}
                                    </Grid2>
                                )
                            }
                        </UsesStepRow>
                    }
                    {/* Quatrième étape ajout/modification d'un usage : irrigation */}
                    {
                        step >= USAGE_IRRIG_STEP.IRRIGATION_GROUND_STEP && (isCulture) &&
                        <UsesStepRow step={USAGE_IRRIG_STEP.IRRIGATION_GROUND_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.irrigatedGround}>
                            {
                                step === USAGE_IRRIG_STEP.IRRIGATION_GROUND_STEP ?
                                    <>
                                        <InputRow
                                            type='Number'
                                            label={i18n.surface}
                                            value={newUsage.area}
                                            onChange={(e) => setNewUsage({ ...newUsage, area: Number(e.target.value) })}
                                            InputProps={{ inputProps: { min: 0 } }}
                                        />
                                        <SimpleSelect
                                            fullWidth
                                            noNullValue
                                            label={i18n.typeSol}
                                            id={i18n.typeSol}
                                            value={newUsage.groundType}
                                            options={usagesTypesSols}
                                            keyValue={'code'}
                                            onChange={(code) => setNewUsage({ ...newUsage, groundType: Number(code) })}
                                        />
                                        <SimpleSelect
                                            fullWidth
                                            noNullValue
                                            label={i18n.profondeurSol}
                                            id={i18n.profondeurSol}
                                            value={newUsage.groundDepth}
                                            options={usagesProfondeurSols}
                                            keyValue={'code'}
                                            onChange={(code) => setNewUsage({ ...newUsage, groundDepth: Number(code) })}
                                        />
                                        <SimpleSelect
                                            fullWidth
                                            noNullValue
                                            label={i18n.nbRotations}
                                            id={i18n.nbRotations}
                                            value={newUsage.nbRotation ?? 1}
                                            options={Array(getSettingInt(applicationSettings, 'nbRotationsCultMax')).fill().map((_, i) => ({ code: i + 1, name: i + 1 }))}
                                            keyValue={'code'}
                                            onChange={(code) => setNewUsage({ ...newUsage, nbRotation: Number(code) }) }
                                        />
                                    </>
                                    :
                                    <Grid2 sx={{ color: lightGrey, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>{newUsage.area}{i18n.ha} {i18n.onGround} {getSandreLabel(codesSandre, 'USAGES.TYPES_SOLS', newUsage.groundType)} {i18n.ofDepth} {getSandreLabel(codesSandre, 'USAGES.PROFONDEUR_SOLS', newUsage.groundDepth)} {i18n.for} {newUsage.nbRotation} {getI18nTitleDataLength(i18n.rotation, i18n.rotations, newUsage.nbRotation)}</Grid2>
                            }
                        </UsesStepRow>
                    }
                    {/* Cinquième étape ajout/modification d'un usage : irrigation */}
                    {
                        (step >= USAGE_IRRIG_STEP.POINT_SOLICITATION_STEP && isCulture) && (
                            <UsesStepRow step={USAGE_IRRIG_STEP.POINT_SOLICITATION_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.requestPrelevement}>
                                {
                                    step === USAGE_IRRIG_STEP.POINT_SOLICITATION_STEP ?
                                        <>
                                            <RadioGroup
                                                column
                                                name='pointSolit'
                                                onChange={(e) => setNewUsage({ ...newUsage, requestPrelevement: Number(e.target.value) })}
                                                sx={{
                                                    color: textColor
                                                }}
                                            >
                                                {usagesType.map(type => (
                                                    <FormControlLabel
                                                        checked={newUsage.requestPrelevement === type.code}
                                                        value={type.code}
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    color: textColor,
                                                                    '&.Mui-checked': {
                                                                        color: textColor,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={type.name}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </>
                                        :
                                        <Grid2 sx={{ color: lightGrey, fontSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>{i18n.request} {lowerCase(getSandreLabel(codesSandre, 'USAGES.TYPES', newUsage.requestPrelevement))}</Grid2>
                                }
                            </UsesStepRow>
                        )
                    }
                    {/* Étape finale ajout/modification d'un usage : irrigation */}
                    {
                        step >= USAGE_IRRIG_STEP.REQUEST_STEP && isCulture &&
                        <UsesStepRow step={USAGE_IRRIG_STEP.REQUEST_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.requestedVolume}>
                            <>
                                {((isReal && (isVolumeRealEntry || (showVolumeIfCounter && countersId.length))) ||
                                    (!isReal && !isCalculAuto)) && (
                                    <Grid2 container size={12} spacing={1} justifyContent='space-between' alignItems='flex-start'>
                                        <Grid2 container size={!isReal && isCheckCoherence ? 8 : 12}>
                                            <InputRow
                                                type='Number'
                                                label={i18n.volumeYearlyWithUnit}
                                                value={newUsage.requestedYearVolume || ''}
                                                onChange={(e) => setNewUsage({
                                                    ...newUsage,
                                                    requestedYearVolume: Number(e.target.value) ? (Number(e.target.value) < 0 ? 0 : Number(e.target.value)) : 0
                                                })}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                error={isCheckCoherence && (newUsage.requestedYearVolume > volumeCalculated)}
                                                helperText={isCheckCoherence && (newUsage.requestedYearVolume > volumeCalculated) ? i18n.volumeAskedGreaterThanSimu : ''}
                                            />
                                        </Grid2>
                                        {!isReal && isCheckCoherence && (
                                            <Grid2 container size={4}>
                                                <InputRow
                                                    label={i18n.indicativeSimu}
                                                    value={`${formatMilliers(volumeCalculated) || 0} m3`}
                                                    disabled
                                                    sx={disableClass}
                                                />
                                            </Grid2>
                                        )}
                                        <Grid2 container size={12}>
                                            <InputRow
                                                type='Number'
                                                label={i18n.lowWaterVolume}
                                                value={newUsage.lowWaterVolume}
                                                onChange={(e) => setNewUsage({
                                                    ...newUsage,
                                                    lowWaterVolume: Number(e.target.value) ? (Number(e.target.value) < 0 ? 0 : Number(e.target.value)) : undefined
                                                })}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                error={isCheckCoherence && (newUsage.lowWaterVolume > volumeCalculated)}
                                                helperText={isCheckCoherence && (newUsage.lowWaterVolume > volumeCalculated) ? i18n.volumeAskedGreaterThanSimu : ''}
                                            />
                                        </Grid2>
                                    </Grid2>
                                )}
                                {!isReal && isCalculAuto && (
                                    <>
                                        <Grid2 container size={12} spacing={1} justifyContent='space-between' alignItems='center'>
                                            <Grid2 container size={(ajustEstim || newUsage.adjustedVolume) ? 5.5 : 12}>
                                                <InputRow
                                                    label={i18n.indicativeSimu}
                                                    value={`${formatMilliers((ajustEstim || newUsage.adjustedVolume) ? volumeCalculated : newUsage.requestedYearVolume) || 0} m3`}
                                                    disabled
                                                    sx={disableClass}
                                                />
                                            </Grid2>
                                            {(ajustEstim || newUsage.adjustedVolume) && (
                                                <>
                                                    <ArrowCircleRightOutlined fontSize='large' sx={{ color: mainColor }} />
                                                    <Grid2 size={5.5} style={{ paddingLeft: 0 }}>
                                                        <InputRow
                                                            label={i18n.ajustedSimu}
                                                            value={`${formatMilliers(newUsage.requestedYearVolume) || 0} m3`}
                                                            disabled
                                                            sx={disableClass}
                                                        />
                                                    </Grid2>
                                                </>
                                            )}
                                        </Grid2>
                                        {!(ajustEstim || newUsage.adjustedVolume) ? (
                                            <OrangeButton
                                                onClick={() => setAjustEstim(true)}
                                                margintop
                                            >
                                                {i18n.ajustEstim}
                                            </OrangeButton>
                                        ) : (
                                            <>
                                                <Grid2 container spacing={1}>
                                                    <Grid2 size={4.5}>
                                                        <MainButton
                                                            onClick={() => {
                                                                setNewUsage({
                                                                    ...newUsage,
                                                                    requestedYearVolume: (newUsage.requestedYearVolume - Math.trunc(volumeCalculated / 10)),
                                                                    adjustedVolume: (newUsage.requestedYearVolume - Math.trunc(volumeCalculated / 10)) !== newUsage.requestedYearVolume,
                                                                })
                                                            }}
                                                            disabled={(newUsage.requestedYearVolume - Math.trunc(volumeCalculated / 10)) < 0}
                                                            margintop
                                                        >
                                                            {i18n.remove10Percent}
                                                        </MainButton>
                                                    </Grid2>
                                                    <Grid2 size={3}>
                                                        <MainButton
                                                            onClick={() => {
                                                                setNewUsage({
                                                                    ...newUsage,
                                                                    requestedYearVolume: volumeCalculated,
                                                                })
                                                            }}
                                                            margintop
                                                        >
                                                            {i18n.reset}
                                                        </MainButton>
                                                    </Grid2>
                                                    <Grid2 size={4.5}>
                                                        <MainButton
                                                            onClick={() => {
                                                                setNewUsage({
                                                                    ...newUsage,
                                                                    requestedYearVolume: (newUsage.requestedYearVolume + Math.trunc(volumeCalculated / 10)),
                                                                    adjustedVolume: (newUsage.requestedYearVolume + Math.trunc(volumeCalculated / 10)) !== newUsage.requestedYearVolume,
                                                                })
                                                            }}
                                                            margintop
                                                        >
                                                            {i18n.add10Percent}
                                                        </MainButton>
                                                    </Grid2>
                                                </Grid2>
                                            </>
                                        )}
                                    </>
                                )}
                                <InputRow
                                    label={i18n.comment}
                                    value={newUsage.comment}
                                    multiline
                                    rows={4}
                                    onChange={(e) => setNewUsage({
                                        ...newUsage,
                                        comment: e.target.value ?? null
                                    })}
                                />
                            </>
                        </UsesStepRow>
                    }
                    {/* Étape finale ajout/modification d'un usage : irrigation simplifiée */}
                    {
                        step >= USAGE_SIMPLE_IRRIG_STEP.REQUEST_SIMPLIFIED_CULT_STEP && isSimplifiedCulture &&
                        <UsesStepRow step={USAGE_SIMPLE_IRRIG_STEP.REQUEST_SIMPLIFIED_CULT_STEP} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.requestedVolume}>
                            <Grid2 container spacing={1} justifyContent='space-between' alignItems='flex-start'>
                                <Grid2 size={isCheckCoherence && !isReal ? 8 : 12}>
                                    {((isReal && (isVolumeRealEntry || (showVolumeIfCounter && countersId.length))) ||
                                      (!isReal && !isCalculAuto)) && (
                                        <InputRow
                                            type='Number'
                                            label={i18n.volumeYearlyWithUnit}
                                            value={newUsage.requestedYearVolume || ''}
                                            onChange={(e) => setNewUsage({
                                                ...newUsage,
                                                requestedYearVolume: Number(e.target.value) ? (Number(e.target.value) < 0 ? 0 : Number(e.target.value)) : undefined
                                            })}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            error={!isReal && isCheckCoherence && (newUsage.requestedYearVolume > volumeCalculated)}
                                            helperText={!isReal && isCheckCoherence && (newUsage.requestedYearVolume > volumeCalculated) ? i18n.volumeAskedGreaterThanSimu : ''}
                                        />
                                    )}
                                </Grid2>
                                {!isReal && isCheckCoherence && (
                                    <Grid2 size={4}>
                                        <InputRow
                                            label={i18n.indicativeSimu}
                                            value={`${formatMilliers(volumeCalculated) || 0} m3`}
                                            disabled
                                            sx={disableClass}
                                        />
                                    </Grid2>
                                )}
                                <Grid2 size={12}>
                                    <InputRow
                                        type='Number'
                                        label={i18n.lowWaterVolume}
                                        value={newUsage.lowWaterVolume}
                                        onChange={(e) => setNewUsage({
                                            ...newUsage,
                                            lowWaterVolume: Number(e.target.value) ? (Number(e.target.value) < 0 ? 0 : Number(e.target.value)) : undefined
                                        })}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        error={isCheckCoherence && (newUsage.lowWaterVolume > volumeCalculated)}
                                        helperText={isCheckCoherence && (newUsage.lowWaterVolume > volumeCalculated) ? i18n.volumeAskedGreaterThanSimu : ''}
                                    />
                                </Grid2>
                                {!isReal && isCalculAuto && (
                                    <>
                                        <Grid2 container spacing={1} justifyContent='space-between' alignItems='center'>
                                            <Grid2 size={(ajustEstim || newUsage.adjustedVolume) ? 5.5 : 12}>
                                                <InputRow
                                                    label={i18n.indicativeSimu}
                                                    value={`${formatMilliers((ajustEstim || newUsage.adjustedVolume) ? volumeCalculated : newUsage.requestedYearVolume) || 0} m3`}
                                                    disabled
                                                    sx={disableClass}
                                                />
                                            </Grid2>
                                            {(ajustEstim || newUsage.adjustedVolume) && (
                                                <>
                                                    <ArrowCircleRightOutlined fontSize='large' sx={{ color: mainColor }} />
                                                    <Grid2 size={5.5} style={{ paddingLeft: 0 }}>
                                                        <InputRow
                                                            label={i18n.ajustedSimu}
                                                            value={`${formatMilliers(newUsage.requestedYearVolume) || 0} m3`}
                                                            disabled
                                                            sx={disableClass}
                                                        />
                                                    </Grid2>
                                                </>
                                            )}
                                        </Grid2>
                                        {!(ajustEstim || newUsage.adjustedVolume) ? (
                                            <Grid2 size={12}>
                                                <OrangeButton
                                                    onClick={() => setAjustEstim(true)}
                                                    margintop
                                                >
                                                    {i18n.ajustEstim}
                                                </OrangeButton>
                                            </Grid2>
                                        ) : (
                                            <>
                                                <Grid2 container spacing={1}>
                                                    <Grid2 size={4.5}>
                                                        <MainButton
                                                            onClick={() => {
                                                                setNewUsage({
                                                                    ...newUsage,
                                                                    requestedYearVolume: (newUsage.requestedYearVolume - Math.trunc(volumeCalculated / 10)),
                                                                    adjustedVolume: (newUsage.requestedYearVolume - Math.trunc(volumeCalculated / 10)) !== newUsage.requestedYearVolume,
                                                                })
                                                            }}
                                                            disabled={(newUsage.requestedYearVolume - Math.trunc(volumeCalculated / 10)) < 0}
                                                            margintop
                                                        >
                                                            {i18n.remove10Percent}
                                                        </MainButton>
                                                    </Grid2>
                                                    <Grid2 size={3}>
                                                        <MainButton
                                                            onClick={() => {
                                                                setNewUsage({
                                                                    ...newUsage,
                                                                    requestedYearVolume: volumeCalculated,
                                                                })
                                                            }}
                                                            margintop
                                                        >
                                                            {i18n.reset}
                                                        </MainButton>
                                                    </Grid2>
                                                    <Grid2 size={4.5}>
                                                        <MainButton
                                                            onClick={() => {
                                                                setNewUsage({
                                                                    ...newUsage,
                                                                    requestedYearVolume: (newUsage.requestedYearVolume + Math.trunc(volumeCalculated / 10)),
                                                                    adjustedVolume: (newUsage.requestedYearVolume + Math.trunc(volumeCalculated / 10)) !== newUsage.requestedYearVolume,
                                                                })
                                                            }}
                                                            margintop
                                                        >
                                                            {i18n.add10Percent}
                                                        </MainButton>
                                                    </Grid2>
                                                </Grid2>
                                            </>
                                        )}
                                    </>
                                )}
                                <Grid2 size={12}>
                                    <InputRow
                                        label={i18n.comment}
                                        value={newUsage.comment}
                                        multiline
                                        rows={4}
                                        onChange={(e) => setNewUsage({
                                            ...newUsage,
                                            comment: e.target.value ?? null
                                        })}
                                    />
                                </Grid2>
                            </Grid2>
                        </UsesStepRow>
                    }
                    {/* Étape finale ajout/modification d'un usage : usage différent d'irrigation */}
                    {
                        (step >= nbStep && !isSimplifiedCulture && !isCulture) && (
                            <UsesStepRow step={nbStep} currentStep={step} nbStep={nbStep} setStep={setStep} title={i18n.requestedVolume}>
                                <>
                                    <InputRow
                                        label={i18n.comment}
                                        value={newUsage.comment}
                                        multiline
                                        rows={4}
                                        onChange={(e) => setNewUsage({
                                            ...newUsage,
                                            comment: e.target.value ?? null
                                        })}
                                    />
                                </>
                            </UsesStepRow>
                        )
                    }
                    <div ref={bottom}/>
                </Grid2>
            </DialogContentMUIDesktop>
            <DialogActions sx={{ width: '100%', padding: 0 }}>
                <Grid2 container justifyContent='flex-end' spacing={2}>
                    <Grid2>
                        <TextButton sx={{ margin: 0 }} disabled={step === USAGE_TYPE_STEP} onClick={() => setStep(step - 1)}>{i18n.previous}</TextButton>
                    </Grid2>
                    <Grid2>
                        <MainButton sx={{ margin: 0 }} disabled={buttonDisabled} onClick={() => step < nbStep ? setStep(step + 1) : saveUsage()}>{step === nbStep ? i18n.validate : i18n.next}</MainButton>
                    </Grid2>
                </Grid2>
            </DialogActions>
        </DialogMUI>
    )
}

ModalUsage.propTypes = {
    usage: PropTypes.shape({
        volumesMonths: PropTypes.string,
        idCulture: PropTypes.number,
    }),
    update: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    countersId: PropTypes.arrayOf(PropTypes.number),
    currentYear: PropTypes.bool,
    defaultCategory: PropTypes.number,
    isReal: PropTypes.bool,
}


export default ModalUsage
