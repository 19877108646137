'use strict'

import { RECEIVE_DOCUMENTS_FILES } from '../constants/FileConstants'
import DtoFile from '../dto/DtoFile'


export const store = {
    pictures: [],
    files: [],
}

export function FileReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_DOCUMENTS_FILES:
            return {
                ...state,
                files: action.files.map(el => new DtoFile(el)),
            }
        default:
            return state
    }
}
