import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/system'
import { mainColor, darkRed, lightOrange } from './theme'

const defaultStyle = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    margin: '5px 0',
    lineHeight: 'normal',
}

export const MainButton = styled(({ sx, ...otherProps }) => (
    <Button
        variant='contained'
        sx={{
            height: otherProps.height || 'auto',
            width: otherProps.noFullWidth ? '' : '100%',
            border: otherProps.reverse ? `1px solid ${mainColor}` : 'none',
            color: `${otherProps.disabled ? 'grey' : (otherProps.reverse ? mainColor : 'white')} !important`,
            backgroundColor: otherProps.disabled ? '' : `${otherProps.reverse ? 'transparent' : mainColor} !important`,
            marginTop: otherProps.margintop ? '1rem' : '0',
            minHeight: otherProps.little ? 35 : 45,
            // minWidth: otherProps.little ? 325 : 340,
            fontSize: 16,
            lineHeight: '20px',
            letterSpacing: '0.1px',
            borderRadius: 100,
            ...defaultStyle,
            ...sx,
        }}
        disableElevation
        {...otherProps}
    />
))({})

export const TextButton = styled(({ sx, ...otherProps }) => (
    <Button
        variant='text'
        sx={{
            height: otherProps.height || 'auto',
            width: otherProps.noFullWidth ? 'auto' : '100%',
            color: `${otherProps.reverse || otherProps.disabled ? 'grey' : mainColor} !important`,
            marginTop: otherProps.margintop ? '1rem' : '0',
            minHeight: otherProps.little ? 35 : 45,
            // minWidth: otherProps.little ? 325 : 340,
            fontSize: 16,
            ...defaultStyle,
            borderRadius: 100,
            ...sx,
        }}
        disableElevation
        {...otherProps}
    />
))({})

export const RedButton = styled(({ sx, ...otherProps }) => (
    <Button
        variant='contained'
        sx={{
            height: 'auto',
            width: otherProps.noFullWidth ? 'fit-content' : '100%',
            border: otherProps.reverse ? `1px solid ${darkRed}` : 'none',
            color: `${otherProps.reverse || otherProps.disabled ? 'grey' : 'white'}`,
            backgroundColor: otherProps.disabled ? '' : `${otherProps.reverse ? 'white' : darkRed}`,
            marginTop: otherProps.margintop ? '1rem' : '0',
            minHeight: otherProps.little ? 35 : 45,
            // minWidth: otherProps.little ? 325 : 340,
            fontSize: 16,
            borderRadius: 100,
            ...defaultStyle,
            ...sx,
            '&:hover': {
                color: `${otherProps.reverse || otherProps.disabled ? 'grey' : 'white'}`,
                backgroundColor: otherProps.disabled ? '' : `${otherProps.reverse ? 'white' : darkRed}`,
            },
            '&:active': {
                color: `${otherProps.reverse || otherProps.disabled ? 'grey' : 'white'}`,
                backgroundColor: otherProps.disabled ? '' : `${otherProps.reverse ? 'white' : darkRed}`,
            },
            '&:focus': {
                color: `${otherProps.reverse || otherProps.disabled ? 'grey' : 'white'}`,
                backgroundColor: otherProps.disabled ? '' : `${otherProps.reverse ? 'white' : darkRed}`,
            },
        }}
        disableElevation
        {...otherProps}
    />
))({})

export const OrangeButton = styled(({ sx, ...otherProps }) => (
    <Button
        variant='contained'
        sx={{
            height: otherProps.height || 'auto',
            width: otherProps.noFullWidth ? 'fit-content' : '100%',
            border: otherProps.reverse ? `1px solid ${mainColor}` : 'none',
            color: `${otherProps.reverse || otherProps.disabled ? 'grey' : 'white'} !important`,
            backgroundColor: otherProps.disabled ? '' : `${otherProps.reverse ? 'white' : lightOrange} !important`,
            marginTop: otherProps.margintop ? '1rem' : '0',
            minHeight: otherProps.little ? 35 : 45,
            // minWidth: otherProps.little ? 325 : 340,
            fontSize: 16,
            borderRadius: 100,
            ...defaultStyle,
            ...sx,
        }}
        disableElevation
        {...otherProps}
    />
))({})