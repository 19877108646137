/* eslint-disable no-constant-condition */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { Card, Grid2, Icon, useTheme } from '@mui/material'
import { ArrowForwardIos, CheckCircleOutlineRounded, Check, Error, Place, Done, Circle, Create } from '@mui/icons-material'
import { CMS_PATH } from '../../../conf/basepath'
import AgriAction from '../agri/actions/AgriAction'
import { getDate, getDateAndHour } from '../../../utils/DateUtil'
import { compact, orderBy, template, uniq } from 'lodash'
import { OrangeButton, RedButton, TextButton } from '../components/styled/buttons'
import { ACTU_TO_HIDE, LAST_TIME_HOME } from '../../offline/constants/HomeConstants'
import moment from 'moment'
import { menuColor, notifBlue, textColor, veryLightColor } from '../components/styled/theme'
import { LightCard } from '../components/styled/grid'
import { CHRONICLES_CONSTANTS, DECLARATION_STATUS, RESTRICTION_RESOURCE_TYPE, SAMPLE_TYPES, SURVEY_TYPE } from '../agri/constants/AgriConstants'
import { getSandreLabel } from '../../../utils/StringUtil'
import { mainColor } from '../components/styled/theme'
import { MainButton } from '../components/styled/buttons'
import { convertToRGB } from '../../../utils/ColorUtil'
import { orderPointsList } from '../../../utils/ObjectUtils'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import LoadingCard from '../components/cards/LoadingCard'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from '../referencials/materiels/constants/MaterielConstants'
import ModalIndexEntry from '../components/ModalIndexEntry'
import { getDelaySinceLastEntry, getStateLabel } from '../../../utils/AgriUtils'
import { POINT_STATUS_OBJ } from '../referencials/installations/constants/InstallationsConstants'
import ModalEnqueteDesktop from './ModalEnqueteDesktop'
import HomeAction from '../../offline/actions/HomeAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import { CMS_STATUS } from '../cms/constants/CmsConstants'

const HomeDesktop = ({ }) => {
    const [openModal, setOpenModal] = useState(false)
    const [actuToHide, setActuToHide] = useState(JSON.parse(localStorage.getItem(ACTU_TO_HIDE)) || [])
    const [openModalNewIndex, setOpenModalNewIndex] = useState(false)
    const [indexedPoint, setIndexedPoint] = useState({})
    const [declarationLoaded, setDeclarationLoaded] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [exploitationLoaded, setExploitationLoaded] = useState(false)

    const theme = useTheme()

    const {
        declaration,
        surveys,
        exploitation,
        actualities,
        managementUnitsRestrictions,
        waterTurnsRestrictions,
        installations,
        droughtSectorsRestrictions,
        managementUnits,
        codesSandre,
        accountStatistics,
        variousMateriels,
    } = useSelector(store => ({
        declaration: store.AgriReducer.declaration,
        surveys: store.AgriReducer.surveys,
        exploitation: store.AgriReducer.exploitation,
        actualities: store.CmsReducer.actualities,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        installations: store.InstallationsReducer.installations,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        managementUnits: store.ReferencialReducer.managementUnits,
        codesSandre: store.ReferencialReducer.codesSandre,
        accountStatistics: store.AccountReducer.accountStatistics,
        variousMateriels: store.InstallationsReducer.variousMateriels,
    }), shallowEqual)

    const materielsTypeForIndex = useApplicationSetting('materielsTypeForIndex', s => s?.split(',')?.map(id => parseInt(id)))
    const typeRestriction = useApplicationSetting('agriTypeRestriction') ?? RESTRICTION_RESOURCE_TYPE.MANAGEMENT_UNITS

    const dispatch = useDispatch()

    const navigate = (path) => {
        dispatch(push(path))
        window.scrollTo(0, 0)
    }

    const onNavigateEnquete = () => {
        setOpenModal(false)
        if (declaration.statusCode === DECLARATION_STATUS.NOT_START) {
            navigate('/declaration/start')
        } else {
            dispatch(AgriAction.updateDeclaration({ lastStep: 1, statusCode: DECLARATION_STATUS.STARTING, ...declaration })).then(() => {
                dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then(() => {
                    navigate('/declaration')
                })
            })
        }
    }
    const date = moment().valueOf()

    useEffect(() => {
        localStorage.removeItem(LAST_TIME_HOME)
        localStorage.setItem(LAST_TIME_HOME, date)
        if (!exploitation.idExploitation) {
            dispatch(AgriAction.fetchExploitation()).then(() => setExploitationLoaded(true))
        } else {
            setExploitationLoaded(true)
        }
    }, [])

    const { idExploitation } = useMemo(() => {
        return {
            idExploitation: exploitation.idExploitation,
        }
    }, [exploitation])

    useEffect(() => {
        if (exploitationLoaded) {
            const dep = uniq(exploitation?.cities?.map((c) => c.slice(0, 2))).toString()
            if (dep && dep !== '') {
                dispatch(HomeAction.fetchArrests(dep)).then(() => {
                    dispatch(InstallationsAction.fetchInstallationsByExploitationId(idExploitation)).then(() => {
                        setDataLoaded(true)
                    })
                })
            } else {
                dispatch(InstallationsAction.fetchInstallationsByExploitationId(idExploitation)).then(() => {
                    setDataLoaded(true)
                })
            }
        }
    }, [exploitationLoaded])

    useEffect(() => {
        if (exploitation.idExploitation) {
            if (!declaration.idDeclaration) {
                dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then((find) => {
                    if (find && !find.statusCode) {
                        setOpenModal(true)
                    }
                    setDeclarationLoaded(true)
                })
            } else setDeclarationLoaded(true)
        }
    }, [exploitation])

    const toggleModal = () => {
        const updateDeclaration = {
            ...declaration,
            statusCode: -1,
        }
        dispatch(AgriAction.updateDeclaration(updateDeclaration)).then(() => {
            setOpenModal(!openModal)
        })
    }

    const enquete = useMemo(() => {
        return surveys.find((s) => s.idSurvey === declaration.idSurvey) || {}
    }, [surveys, declaration])

    const openDocument = (document) => {
        window.open(CMS_PATH + document.name, '_system')
    }

    const getArrestsCards = () => {
        const allPoints = exploitation?.link_samplings?.map((linkPoint) => {
            return installations.find((i) => i.id === linkPoint.idInstallation) || {}
        }) || []

        const restrictions = (() => {
            if (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS) {
                return droughtSectorsRestrictions.filter((dsR) => allPoints.find((point) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType))
            }
            return managementUnitsRestrictions.filter((ugeR) => allPoints.find((point) => ugeR.managementCode === point.subManagementCode && point.sampleType === ugeR.resourceType)) || managementUnitsRestrictions.filter((ugeR) => allPoints.find((point) => ugeR.managementCode === point.managementCode && point.sampleType === ugeR.resourceType))
        })()

        return restrictions.filter((r) => r.idRestriction !== -1 && !actuToHide.some((rHide) => rHide.idRestriction === r.idRestriction &&
            rHide.resourceType === r.resourceType &&
            (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS ? (rHide.idSector === r.idSector) : (rHide.managementCode === r.managementCode)) &&
            rHide.date > r.updateDate
        )).map((r) => {
            const resourceLabel = (() => {
                if (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS) {
                    return getSandreLabel(codesSandre, 'PREL_AGRI.SECTEUR_SECHERESSE', r.idSector)
                }
                return managementUnits.find((m) => m.managementCode === r.managementCode)?.codeWithLabel
            })()
            const waterTurnsRestriction = waterTurnsRestrictions.find((wtR) => wtR.id === r.idRestriction) || {}
            const actuToSave = {
                ...r,
                waterTurnsRestriction,
                resourceLabel,
                date,
            }
            return {
                date: r.updateDate,
                content: (
                    <Card sx={{ backgroundColor: 'white', marginBottom: '10px', borderRadius: '15px' }}>
                        <Grid2 container alignItems='center' sx={{ backgroundColor: `rgba(${convertToRGB(waterTurnsRestriction.color)}, 0.3)`, padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`, borderRadius: '15px' }}>
                            <Grid2 container alignItems='center' justifyContent='space-between' size={12} paddingBottom={2}>
                                <Grid2 container alignItems='center' size={6} >
                                    <Grid2 ><Place htmlColor={waterTurnsRestriction.color} /></Grid2>
                                    <Grid2 width='fit-content' >{resourceLabel}</Grid2>
                                </Grid2>
                                <Grid2 textAlign='right' size={6}><Error htmlColor={waterTurnsRestriction.color} /></Grid2>
                            </Grid2>
                            <Grid2 size={12} className='bold' sx={{ fontSize: '12px' }}>{`${getDate(r.updateDate)} - ` + `${r.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial}`}</Grid2>
                            <Grid2 size={12} className='bold' sx={{ padding: '0.5 0', fontSize: '16px' }}>{`${waterTurnsRestriction.label} - ${waterTurnsRestriction.description}`}</Grid2>
                            <Grid2 size={12} sx={{ fontSize: '16px' }}>{waterTurnsRestriction.comment || ''} {r.exemption || ''}</Grid2>
                            <Grid2 size={12} container justifyContent='flex-end'>
                                <TextButton
                                    little
                                    bgColor={waterTurnsRestriction.color}
                                    onClick={() => {
                                        localStorage.setItem(ACTU_TO_HIDE, JSON.stringify([ ...actuToHide.filter((a) => (a.managementCode !== actuToSave.managementCode)), actuToSave ]))
                                        setActuToHide([...actuToHide.filter((a) => (a.managementCode !== actuToSave.managementCode)), actuToSave ])
                                    }}
                                    sx={{ width: 'fit-content' }}
                                    startIcon={<Check />}
                                >
                                    {i18n.iUnderstood}
                                </TextButton>
                            </Grid2>
                        </Grid2>
                    </Card>
                ),
            }
        })
    }


    const getActualitiesCards = () => {
        const lastLogin = orderBy(accountStatistics.filter((s) => (s.eventType === 'LOGIN')), ['statisticDate'], ['desc'])[1]
        return [...actualities.filter((a) => a.status === CMS_STATUS.PUBLISHED && (!a.dateDebut || a.dateDebut < date) && (!a.dateFin || a.dateFin > date)).map((a) => ({
            date: a.dateDebut || a.updateDate,
            content: (
                <Grid2 size={12}>
                    <LightCard container sx={{ padding: 2, backgroundColor: lastLogin?.statisticDate < a.updateDate ? menuColor : veryLightColor }}>
                        <Grid2 size={lastLogin?.statisticDate < a.updateDate ? 11 : 12} sx={{ fontSize: '24px', lineHeight: '32px' }}>{a.title}</Grid2>
                        { lastLogin?.statisticDate < a.updateDate ? (<Grid2 size={1}><Circle sx={{ color: notifBlue, height: '16px' }} /></Grid2>) : null }
                        <Grid2 size={12} sx={{ fontSize: '14px' }}><b>{a.subtitle ? `${a.subtitle} - ` : null}</b>{getDate(a.dateDebut || a.updateDate)}</Grid2>
                        <div dangerouslySetInnerHTML={{ __html: a.comment || '' }} style={{ width: 'fit-content' }}/>
                        {a.link && a.link !== '' && (
                            <p
                                className='blue-text clickable'
                                onClick={() => window.open(a.link, '_system')}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    wordBreak: 'break-all',
                                }}
                            >
                                {a.link}
                                <Icon>launch</Icon>
                            </p>
                        )}
                        {!!a?.document?.length && (
                            <Grid2
                                container
                                justifyContent='flex-end'
                                alignItems='center'
                            >
                                <TextButton
                                    onClick={() => openDocument(a.document[0])}
                                    noFullWidth
                                    endIcon={<ArrowForwardIos sx={{ marginLeft: '10px', fontSize: '20px' }} />}
                                    sx={{ margin: 0, padding: `0 ${theme.spacing(2)}` }}
                                >
                                    {i18n.seeDocument}
                                </TextButton>
                            </Grid2>
                        )}
                    </LightCard>
                </Grid2>
            )
        })), ...actuToHide.map((a) => ({
            date: a.updateDate,
            content: (
                <Grid2 size={12}>
                    <LightCard sx={{ backgroundColor: 'white', padding: 0, borderRadius: '15px' }}>
                        <Grid2 container justifyContent='space-between' alignItems='center' sx={{ backgroundColor: `rgba(${convertToRGB(a?.waterTurnsRestriction?.color)}, 0.3)`, padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`, borderRadius: '20px' }}>
                            <Grid2 size={1}><Place htmlColor={a?.waterTurnsRestriction?.color} /></Grid2>
                            <Grid2 size={11} sx={{ fontSize: '14px', lineHeight: '20px' }}>{a.resourceLabel}</Grid2>
                            <Grid2 size={12} sx={{ fontSize: '24px', paddingTop: 2, color: a?.waterTurnsRestriction?.color }}>{`${a?.waterTurnsRestriction?.label} - ${a?.waterTurnsRestriction?.description}`}</Grid2>
                            <Grid2 size={12} className='bold' sx={{ paddingTop: 0.5, fontSize: '14px', lineHeight: '20px' }}>{`${getDate(a.updateDate)} - ` + `${a?.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial}`}</Grid2>
                            <Grid2 size={12} sx={{ fontSize: '14px', paddingTop: 2, lineHeight: '20px' }}>{`${a?.waterTurnsRestriction?.comment || ''} - ${a.exemption || ''}`}</Grid2>
                            <Grid2 size={1} /> <Grid2 container size={11} sx={{ padding: `${theme.spacing(1.2)} 0`, fontStyle: 'italic', fontSize: '12px' }}alignItems='center' justifyContent='flex-end' ><Done sx={{ fontSize: '12px' }}/>{i18n.viewAt} {getDate(a.date)}</Grid2>
                        </Grid2>
                    </LightCard>
                </Grid2>
            ),
        }))]
    }

    const getMaterial = (idVariousMaterial) => {
        return variousMateriels.find(m => m.id === idVariousMaterial)
    }

    const getSitusAndIdsVarious = (idInstallation) => {
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_POINT_PUMP && m.siteCode === idInstallation && (m.situationEndDate ? m.situationEndDate > date : true) && (m.situationDate ? m.situationDate < date : true)) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === SITU_PUMP_COUNTER && pointPumps.find(({ idVarious }) => idVarious === m.siteCode) && (m.situationEndDate ? m.situationEndDate > date : true) && (m.situationDate ? m.situationDate < date : true)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        return { pumpsIds, countersIds, pointPumps, pointCounters }
    }


    const getWarningCondition = (idInstallation) => {
        const { pumpsIds, countersIds, pointPumps, pointCounters } = getSitusAndIdsVarious(idInstallation)
        const ids = [...pumpsIds, ...countersIds]
        const pointMats = [...pointPumps, ...pointCounters]
        const materialsSeizableIndex = ids?.map(id => getMaterial(id))?.filter(m => materielsTypeForIndex?.includes(m?.materielType))
        if (materialsSeizableIndex?.length > 0) {
            const lastIndex = orderBy(exploitation.link_chronicles.filter((c) => materialsSeizableIndex.map(m => m.id).includes(c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX), 'measureDate', 'desc')[0]
            if (lastIndex) {
                return { idMatLastChronicle: lastIndex.idMat, days: lastIndex.measureDate, important: true, estim: false }
            }
            const lastAffectIndex = orderBy(pointMats.filter(m => materialsSeizableIndex.some(mat => mat.id === m.idVarious)), 'situationDate', 'desc')[0]
            if (lastAffectIndex) {
                return materialsSeizableIndex?.length === 1 ? { idMatLastChronicle: lastAffectIndex.idVarious, days: lastAffectIndex.situationDate, important: false, estim: false } : { days: lastAffectIndex.situationDate, important: false, estim: false }
            }
        }
        const lastEstim = orderBy(exploitation.link_chronicles.filter((c) => ids.includes(c.idMat) && c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM), 'measureDate', 'desc')[0]
        if (lastEstim) {
            return { idMatLastChronicle: lastEstim.idMat, days: lastEstim.measureDate, important: true, estim: true }
        }
        const lastAffectEstim = orderBy(pointMats.filter(m => materialsSeizableIndex.some(mat => mat.id !== m.idVarious)), 'situationDate', 'desc')[0]
        if (lastAffectEstim) {
            return { days: lastAffectEstim.situationDate, important: false, estim: true }
        }
        return { noIndex: true, days: null }
    }

    const getPoints = () => (
        orderPointsList(compact(exploitation?.link_samplings?.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const { idMatLastChronicle, days, important, estim, noIndex } = getWarningCondition(point.id)
                const { warnDate, warning } = days ?
                    getDelaySinceLastEntry(days, point)
                    :
                    { warnDate: null, warning: true }
                const stateCode = linkPoint.stateCode
                return {
                    ...point,
                    days: warnDate,
                    idMatLastChronicle,
                    warning: (noIndex || stateCode !== POINT_STATUS_OBJ.USED) ? false : warning,
                    important: stateCode !== POINT_STATUS_OBJ.USED ? false : important,
                    estim,
                    noIndex,
                    stateCode,
                    stateLabel: getStateLabel(stateCode),
                }
            }
            return null
        })))
    )


    const getActions = () => {
        const pointsOrdered = getPoints()
        const isAnnualSurvey = enquete?.surveyType === SURVEY_TYPE.ANNUAL_SURVEY
        if (!dataLoaded || !declarationLoaded) {
            return (
                <Grid2 size={12} sx={{ height: '100%' }}>
                    <LoadingCard />
                </Grid2>
            )
        }
        if (declaration?.idDeclaration || pointsOrdered.some(p => p.warning)) {
            return (
                <Grid2 container spacing={1} size={12} alignContent='flex-start' sx={{ overflowY: 'auto', height: '100%', paddingRight: 1 }} >
                    {declaration?.idDeclaration && (
                        <LightCard size={12}>
                            <Grid2 sx={{ fontSize: '24px' }}>{`${declaration.statusCode > DECLARATION_STATUS.STARTING ? i18n.continueMyDeclaration : i18n.startMyDeclaration} ${isAnnualSurvey ? i18n.yearly : i18n.intermediate} ${enquete.year}`}</Grid2>
                            <Grid2 sx={{ fontSize: '14px', marginTop: '10px' }}>
                                {isAnnualSurvey ?
                                    template(i18n.annualSurveyDescription)({ startDate: getDateAndHour(enquete.startDate), endDate: getDateAndHour(enquete.endDate) })
                                    :
                                    template(i18n.intermediateSurveyDescription)({ startDate: getDateAndHour(enquete.startDate), endDate: getDateAndHour(enquete.endDate) })
                                }
                            </Grid2>
                            <Grid2
                                container
                                justifyContent='flex-end'
                                alignItems='center'
                                sx={{ color: mainColor, marginTop: '16px' }}
                            >
                                <MainButton onClick={onNavigateEnquete} sx={{ fontWeight: 400 }}>
                                    {declaration.statusCode > DECLARATION_STATUS.STARTING ? i18n.comeBackToMyDeclaration : i18n.startMyDeclaration }<ArrowForwardIos sx={{ marginLeft: '10px', fontSize: '20px' }} />
                                </MainButton>
                            </Grid2>
                        </LightCard>
                    )}
                    {pointsOrdered.map(p => {
                        if (!p.warning) return null
                        const overdueEntry = p.estim ? i18n.overdueEstimEntry : i18n.overdueIndexEntry
                        const noTitle = p.estim ? i18n.noEstimTitle : i18n.noEntryTitle
                        const lastEntryOnXSinceX = p.estim ? i18n.lastEstimEntryOnXSinceX : i18n.lastIndexEntryOnXSinceX
                        const noOnXSinceX = p.days ? (p.estim ? i18n.noEstimOnXSinceX : i18n.noEntryOnXSinceX) : (p.estim ? i18n.noEstimOnX : i18n.noEntryOnX)
                        return (
                            <LightCard size={12}>
                                <Grid2 sx={{ fontSize: '24px', lineHeight: '32px', paddingBottom: 1 }}>{p.important ? overdueEntry : noTitle}</Grid2>
                                <Grid2 sx={{ fontSize: '14px', lineHeight: '20px', paddingBottom: 2, }}>
                                    { template(p.important ? lastEntryOnXSinceX : noOnXSinceX)({ days: moment().diff(moment(p.days), 'days'), name: `${p?.code} ${p?.name ? `(${p?.name})` : ''}` }) }
                                </Grid2>
                                <Grid2
                                    container
                                    justifyContent='flex-end'
                                    alignItems='center'
                                >
                                    {p.important ?
                                        (<RedButton
                                            startIcon={<Error />}
                                            noFullWidth={true}
                                            sx={{
                                                minHeight: '32px',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setOpenModalNewIndex(true)
                                                setIndexedPoint(p)
                                            }}
                                        >
                                            {template(p.estim ? i18n.noEstimSinceXDays : i18n.noIndexSinceXDays)({ days: moment().diff(moment(p.days), 'days') })}
                                        </RedButton>)
                                        :
                                        (<OrangeButton
                                            startIcon={<Create />}
                                            noFullWidth={true}
                                            sx={{
                                                minHeight: '32px',
                                                fontSize: '12px',
                                                fontWeight: 400,
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                if (p.idMatLastChronicle) {
                                                    setOpenModalNewIndex(true)
                                                    setIndexedPoint(p)
                                                } else {
                                                    navigate(`/index/${p.id}/materiels/`)
                                                }
                                            }}
                                        >
                                            {p.estim || p.noIndex ? i18n.enterEstimQuestion : i18n.enterIndexQuestion}
                                        </OrangeButton>)
                                    }

                                </Grid2>
                            </LightCard>
                        )
                    })}
                </Grid2>
            )
        }
        return (
            <Grid2 container size={12} alignContent='center' justifyContent='center' alignItems='center' height='100%' sx={{ whiteSpace: 'pre-wrap' }}>
                <CheckCircleOutlineRounded sx={{ fontSize: '96px' }} fill={textColor} />
                <Grid2 size={12} sx={{ whiteSpace: 'pre-wrap', fontSize: '24px', lineHeight: '32px' }} textAlign='center'>{i18n.upToDate}</Grid2>
            </Grid2 >
        )
    }

    const arrestsContents = orderBy(getArrestsCards(), 'date', 'desc')

    return (
        <Grid2 container size={12} alignItems={'stretch'} justifyContent={'center'} sx={{ height: '100%' }}>
            <Grid2 size={12} sx={{ fontSize: '22px', lineHeight: '28px', paddingBottom: 2.5, color: textColor }}>
                {i18n.home}
            </Grid2>
            <div style={{ position: 'fixed', zIndex: 1, width: '80%' }}>
                {arrestsContents.map((a) => (a.content))}
            </div>
            <Grid2 container columnSpacing={1} size={12} sx={{ height: 'calc(100% - 2rem)', overflow: 'hidden' }}>
                <Grid2 container size={6} sx={{ height: '100%', color: textColor, paddingBottom: 5.5 }}>
                    <Grid2 size={12} paddingBottom={2} sx={{ fontSize: '22px', lineHeight: '28px' }}>{i18n.actionsTitle}</Grid2>
                    {getActions()}
                </Grid2>
                <Grid2 container size={6} sx={{ height: '100%', color: textColor, paddingBottom: 5.5 }}>
                    <Grid2 size={12} paddingBottom={2} sx={{ fontSize: '22px', lineHeight: '28px' }}>{i18n.actusTitle}</Grid2>
                    <Grid2 size={12} sx={{ overflowY: 'auto', height: '100%', paddingRight: 1 }}>
                        {actualities.length && dataLoaded ? (
                            <Grid2 container alignContent='flex-start' sx={{ maxHeight: '100%', gap: '16px' }}>
                                {orderBy(getActualitiesCards(), 'date', 'desc').map((a) => (a.content))}
                            </Grid2>
                        ) : <LoadingCard />}
                    </Grid2>
                </Grid2>
            </Grid2>
            {openModal && (
                <ModalEnqueteDesktop
                    open={openModal}
                    toggleModal={toggleModal}
                    onClick={onNavigateEnquete}
                    enquete={enquete}
                />
            )}
            {openModalNewIndex && (
                <ModalIndexEntry
                    point={indexedPoint}
                    openModalNewIndex={openModalNewIndex}
                    setOpenModalNewIndex={setOpenModalNewIndex}
                    setIndexedPoint={setIndexedPoint}
                    getMaterial={getMaterial}
                />
            )}
        </Grid2>
    )
}

export default HomeDesktop
