'use strict'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_CULTURES,
    RECEIVE_CULTURES_FAMILIES,
    RECEIVE_CONTRIBUTOR,
    RECEIVE_CONTRIBUTORS,
    RECEIVE_USAGES,
    RECEIVE_SANDRE_CODES,
    RECEIVE_MANAGEMENT_UNITS,
    RECEIVE_ALL_WATERSHEDS,
    RECEIVE_ALL_BOOKMARKS,
    RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS,
} from '../constants/ReferencialConstants'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { aquaFetchV2, checkAuth, getAuthorization } from '../../../../utils/ActionUtils'
import ToastrAction from '../../components/toasters/ToastrAction'
import LogAction from '../../../../utils/log/actions/LogAction'
import { RECEIVE_TANKS_TYPES } from '../../agri/constants/AgriConstants'

const ReferencialAction = {
    receiveManagementUnitsRestrictions: (managementUnitsRestrictions) => {
        return { type: RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS, managementUnitsRestrictions }
    },
    fetchManagementUnitsRestrictions: () => {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.managementUnitsRestrictions(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.managementUnitsRestrictions })
                .then(json => {
                    dispatch(ReferencialAction.receiveManagementUnitsRestrictions(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.restrictions} : ${err}`))
                    ToastrAction.error(i18n.fetchError + i18n.restrictions)
                })
        }
    },

    // TODO
    receiveAllBookmarks(bookmarks) {
        return { type: RECEIVE_ALL_BOOKMARKS, bookmarks }
    },

    fetchBookmarks: () => {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.user.getBookmarks(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.bookmarks })
                .then(json => {
                    dispatch(ReferencialAction.receiveAllBookmarks(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.bookmarks} : ${err}`))
                    ToastrAction.error(i18n.fetchError + i18n.bookmarks)
                })
        }
    },

    createBookmark(bookmark) {
        return dispatch => {
            return fetch(ApplicationConf.user.setBookmark(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(bookmark),
            })
                .then(checkAuth)
                .then(() => {
                    return dispatch(ReferencialAction.fetchBookmarks())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.bookmarks} : ${err}`))
                    ToastrAction.error(i18n.updateError + i18n.bookmarks)
                })
        }
    },

    deleteBookmark(bookmark) {
        return dispatch => {
            return fetch(ApplicationConf.user.setBookmark(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(bookmark),
            })
                .then(checkAuth)
                .then(() => {
                    return dispatch(ReferencialAction.fetchBookmarks())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.bookmarks} : ${err}`))
                    ToastrAction.error(i18n.updateError + i18n.bookmarks)
                })
        }
    },

    receiveWatersheds(watersheds) {
        return { type: RECEIVE_ALL_WATERSHEDS, watersheds }
    },
    fetchWatersheds() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.watersheds(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.watersheds })
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveWatersheds(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watersheds} : ${err}`))
                    ToastrAction.error(i18n.fetchError + i18n.watersheds)
                })
        }
    },

    receiveTanksTypes(tanksTypes) {
        return { type: RECEIVE_TANKS_TYPES, tanksTypes }
    },
    fetchTanksTypes() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.tanksTypes(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.agriTanksTypes })
                .then((json) => {
                    dispatch(ReferencialAction.receiveTanksTypes(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.detentionType} : ${err}`)
                })
        }
    },

    receiveCultures(cultures) {
        return { type: RECEIVE_CULTURES, cultures }
    },
    fetchCultures() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.cultures(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.cultures })
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveCultures(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.cultures} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.cultures)
                })
        }
    },

    receiveCulturesFamilies(culturesFamilies) {
        return { type: RECEIVE_CULTURES_FAMILIES, culturesFamilies }
    },
    fetchCulturesFamilies() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.culturesFamilies(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.culturesFamilies })
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveCulturesFamilies(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.culturesFamilies} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.culturesFamilies)
                })
        }
    },

    receiveContributor(contributor) {
        return { type: RECEIVE_CONTRIBUTOR, contributor }
    },
    fetchContributor(id) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.contributor(id), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.contributors.find((c) => c.id === id) })
                .then((json = {}) => {
                    if (json.id) {
                        dispatch(ReferencialAction.receiveContributor(json))
                    }
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contributor} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contributor)
                })
        }
    },

    receiveContributors(contributors) {
        return { type: RECEIVE_CONTRIBUTORS, contributors }
    },
    fetchContributors() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.contributors(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.contributors })
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveContributors(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.contributors)
                })
        }
    },

    receiveUsages(usages) {
        return { type: RECEIVE_USAGES, usages }
    },
    fetchUsages() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.usages(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.usagesCategories })
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveUsages(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.usages} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.usages)
                })
        }
    },

    receiveCodesSandre(codesSandre) {
        return { type: RECEIVE_SANDRE_CODES, codesSandre }
    },
    fetchCodesSandre() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.sandreCodes(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.codesSandre })
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveCodesSandre(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.codesSandre} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.codesSandre)
                })
        }
    },

    receiveManagementUnits(managementUnits) {
        return { type: RECEIVE_MANAGEMENT_UNITS, managementUnits }
    },
    fetchManagementUnits() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.managementUnits(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().ReferencialReducer.managementUnits })
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveManagementUnits(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.managementUnits} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.managementUnits)
                })
        }
    },
}

export default ReferencialAction
