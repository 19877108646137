'use strict'
import {
    CITIES,
} from '../constants/CityConstants'
import DtoCity from '../dto/DtoCity'
import { createIndex } from '../../../../../utils/StoreUtils'

export const store = {
    cities: [],
    citiesHisto: [],
    citiesIndex: {},
}

export function CityReducer(state = {}, action) {
    switch (action.type) {
        case CITIES:
            const citiesDto = action.cities.map((city) => new DtoCity(city))
            const cities = citiesDto.filter((city) => city.id)
            return {
                ...state,
                cities,
                citiesIndex: createIndex(cities),
            }
        default:
            return state
    }
}
