export default class DtoVariousMatType {
    constructor(obj) {
        this.materielType = obj.materielType // Option[Int],
        this.name = obj.name // Option[String],
        this.provider = obj.provider // Option[Int],
        this.comment = obj.comment // Option[String],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.manufacturerId = obj.manufacturerId // Option[Int],
        this.warranty = obj.warranty // Option[Double]
        this.category = obj.category // Option[Int]
    }
}