import { Grid2 } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AgriAction from '../../../agri/actions/AgriAction'
import ContactAction from '../../../contact/actions/ContactAction'
import { getLogin } from '../../../../../utils/UserUtils'
import { compact, orderBy, parseInt } from 'lodash'
import i18n from 'simple-react-i18n'
import ContactCardDesktop from '../../../components/cards/ContactCardDesktop'
import { Add, Error } from '@mui/icons-material'
import { darkRed, lightRed, mainColor } from '../../../components/styled/theme'
import { MainButton } from '../../../components/styled/buttons'
import ModalContact from '../modals/ModalContact'
import { DECLA_LAST_STEP, DECLARATION_CONTACT_TYPE, DECLARATION_STATUS, DECLARATION_STRUCTURE_TYPE } from '../../../agri/constants/AgriConstants'
import LoadingCard from '../../../components/cards/LoadingCard'
import PropTypes from 'prop-types'

const ContactsValidation = ({ setValidationStep }) => {
    const {
        codesSandre,
        contacts,
        contributors,
        declarationContributors,
        declaration,
        exploitation,
    } = useSelector((store) => ({
        codesSandre: store.ReferencialReducer.codesSandre,
        contacts: store.ContactReducer.contacts,
        contributors: store.ReferencialReducer.contributors,
        declarationContributors: store.AgriReducer.declarationContributors,
        declaration: store.AgriReducer.declaration,
        exploitation: store.AgriReducer.exploitation,
    }), shallowEqual)

    const preleveur = contributors.find((c) => c.id === exploitation.operatorCode) || {}

    const [editMode, setEditMode] = useState(declaration.lastStep === DECLA_LAST_STEP.CONTACTS_STEP)
    const [openModal, setOpenModal] = useState(false)
    const [contactsLoaded, setContactsLoaded] = useState(false)

    const dispatch = useDispatch()

    const contactsDecla = useMemo(() => {
        const contactsExploitation = compact(exploitation?.link_contributors?.map((c) => {
            const linkUpdated = declaration.link_contributorTypes.find((cT) => !cT.isTemp && cT.idContributor === c.contactCode && cT.contributorType === c.contributorType)
            if (!linkUpdated) {
                return {
                    idContributor: c.contactCode,
                    isTemp: false,
                    contributorType: c.contributorType,
                    surveyId: declaration.idSurvey,
                    idExploitation: exploitation.idExploitation,
                    updateLogin: getLogin(),
                    mode: 'u',
                }
            }
            return null
        }))

        const contactsExploitationAndDecla = [...contactsExploitation, ...declaration.link_contributorTypes.filter((cT) => cT.mode !== 'd')]

        const contactsWithRep = (() => {
            if (!contactsExploitationAndDecla.find((cT) => cT.contributorType === DECLARATION_STRUCTURE_TYPE.LEGAL_REP && cT.mode !== 'd') && preleveur.structureType !== DECLARATION_STRUCTURE_TYPE.INDIV_STRUCT) {
                const legalRepresentative = contacts.find((c) => c.id === preleveur.legalRepresentative)
                if (legalRepresentative) {
                    return [
                        ...contactsExploitationAndDecla,
                        {
                            idContributor: legalRepresentative.id,
                            isTemp: false,
                            contributorType: DECLARATION_STRUCTURE_TYPE.LEGAL_REP,
                            surveyId: declaration.idSurvey,
                            idExploitation: exploitation.idExploitation,
                            updateLogin: getLogin(),
                            mode: 'u',
                        },
                    ]
                }
                return contactsExploitationAndDecla
            }
            return contactsExploitationAndDecla
        })()


        setContactsLoaded(true)
        return orderBy(contactsWithRep, 'contributorType')
    }, [exploitation, declaration])

    useEffect(() => {
        if (!contributors.length) {
            dispatch(AgriAction.fetchDeclarationContributors(declaration.idSurvey)).then(() => {
                if (!contacts.length) {
                    dispatch(ContactAction.fetchContacts())
                }
            })
        } else if (!contacts.length) {
            dispatch(ContactAction.fetchContacts())
        }
    }, [])

    const formatContact = (contact, updatedContact) => {
        if (updatedContact) {
            return {
                ...contact,
                ...updatedContact,
                address: updatedContact.road,
                additionalAddress: updatedContact.addressComplement,
                desktopTel: updatedContact.fax,
                postalBox: updatedContact.postalCode,
                default: contact.id === (preleveur.legalRepresentative || null)
            }
        }
        return {
            ...contact,
            default: contact.id === (preleveur.legalRepresentative || null)
        }
    }

    const showLegalRepresentative = useMemo(() => {
        return !contactsDecla.find((c) => c.mode !== 'd' && c.contributorType === DECLARATION_STRUCTURE_TYPE.LEGAL_REP)
    }, [contactsDecla])

    const onSave = (contact, type, previousType) => {
        const id = contact.idContributorTemp || contact.idContributor || contact.id
        const newLink = {
            contributorType: type,
            surveyId: declaration.idSurvey,
            idExploitation: exploitation.idExploitation,
            updateLogin: getLogin(),
        }
        const contribFormatted = {
            ...contact,
            idContributor: id,
            surveyId: declaration.idSurvey,
            contactType: DECLARATION_CONTACT_TYPE.CONTACT,
        }
        setContactsLoaded(false)
        // si le contact est déjà lié
        if (id && contributors.find((cT) => cT.idContributor === id && !!cT.isTemp === !!contact.idContributorTemp && cT.contributorType === type)) {
            dispatch(AgriAction.updateDeclarationContributor(declaration.idSurvey, contribFormatted))
            // si c'est une création de nouveau contact
        } else if (!id) {
            dispatch(AgriAction.updateDeclarationContributor(declaration.idSurvey, contribFormatted, (idReturned) => {
                dispatch(AgriAction.updateDeclaration({
                    ...declaration,
                    ['link_contributorTypes']: [
                        ...declaration.link_contributorTypes,
                        {
                            ...newLink,
                            idContributor: parseInt(idReturned),
                            isTemp: true,
                            mode: 'c',
                        },
                    ]
                }))
            }))
            // si c'est un rajout de lien non existant
        } else {
            dispatch(AgriAction.updateDeclarationContributor(declaration.idSurvey, contribFormatted, () => {
                dispatch(AgriAction.updateDeclaration({
                    ...declaration,
                    ['link_contributorTypes']: [
                        ...(previousType ?
                            declaration.link_contributorTypes.filter((cT) => !(cT.idContributor === id && !!cT.isTemp === !!contact.idContributorTemp && cT.contributorType === previousType)) :
                            declaration.link_contributorTypes),
                        {
                            ...newLink,
                            idContributor: id,
                            isTemp: !!contact.idContributorTemp,
                            mode: previousType ? 'u' : 'c',
                        },
                    ]
                }))
            }))
        }
    }

    const onDelete = (contact, type) => {
        const id = contact.idContributorTemp || contact.idContributor || contact.id
        setContactsLoaded(false)
        dispatch(AgriAction.updateDeclaration({
            ...declaration,
            ['link_contributorTypes']: [
                ...declaration.link_contributorTypes.filter((cT) => !(cT.idContributor === id && !!cT.isTemp === !!contact.idContributorTemp && cT.contributorType === type)),
                {
                    contributorType: type,
                    surveyId: declaration.idSurvey,
                    idExploitation: exploitation.idExploitation,
                    updateLogin: getLogin(),
                    idContributor: id,
                    isTemp: !!contact.idContributorTemp,
                    mode: 'd',
                },
            ]
        }))
    }

    const typesContributors = [{ code: DECLARATION_STRUCTURE_TYPE.LEGAL_REP, name: i18n.legalRepresentative }, ...codesSandre.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT')]

    return (
        <Grid2 container sx={{ height: 'auto', rowGap: '2vh' }}>
            {editMode &&
                <Grid2
                    size={12}
                    sx={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.15px',
                        color: mainColor
                    }}
                >
                    {i18n.pleaseCheckGivenInfos}
                </Grid2>
            }
            {contactsLoaded ? (
                <Grid2 container size={12} justifyContent='space-between' spacing={1}>
                    {contactsDecla.map((contactDecla) => {
                        const contact = contactDecla.isTemp ? {} : (contacts.find((c) => c.id === contactDecla.idContributor) || {})
                        const contactUpdated = declarationContributors.find((c) => (contactDecla.isTemp ? c.idContributorTemp : c.idContributor) === contactDecla.idContributor && c.contactType === DECLARATION_CONTACT_TYPE.CONTACT)
                        const formattedContact = formatContact(contact, contactUpdated)
                        const type = typesContributors.find((t) => t.code === contactDecla.contributorType)
                        return (
                            <Grid2 size={4}>
                                <ContactCardDesktop
                                    showLegalRepresentative={showLegalRepresentative}
                                    contact={formattedContact}
                                    type={type}
                                    editMode={editMode}
                                    onSave={onSave}
                                    onDelete={onDelete}
                                    defaultLegalRepresentative={formattedContact.default}
                                />
                            </Grid2>
                        )
                    })}
                    {editMode ? (
                        <Grid2 container size={4} className='clickable' onClick={() => setOpenModal(true)} alignItems='center' justifyContent='center' sx={{ border: `solid 1px ${mainColor}`, borderRadius: '12px', minHeight: '27.6vh' }}>
                            <Add sx={{ fontSize: '20px', color: mainColor }} />
                            <Grid2
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px',
                                    fontWeight: 500,
                                    color: mainColor
                                }}
                            >
                                {i18n.addContributor}
                            </Grid2>
                        </Grid2>
                    ) : <Grid2 size={4}/>}
                    {contactsDecla.length % 3 === 1 ? <Grid2 size={4}/> : null}
                </Grid2>
            ) : <LoadingCard />}
            {preleveur.structureType !== DECLARATION_STRUCTURE_TYPE.INDIV_STRUCT && showLegalRepresentative ? (
                <Grid2 container size={12} justifyContent='center'>
                    <Grid2
                        container
                        size={12}
                        alignItems='center'
                        justifyContent='center'
                        sx={{
                            columnGap: '1vw',
                            padding: '2vh 3vw',
                            backgroundColor: lightRed,
                            border: `solid 1px ${darkRed}`,
                            borderRadius: '4px',
                            width: 'auto',
                        }}
                    >
                        <Error sx={{ color: darkRed, fontSize: '20px' }}/>
                        <Grid2 sx={{ color: darkRed, fonSize: '14px', lineHeight: '20px', letterSpacing: '0.25px' }}>{i18n.addContactForValidation}</Grid2>
                    </Grid2>
                </Grid2>
            ) : null}
            <Grid2 container size={12} alignItems='center' justifyContent='center'>
                {editMode ? (
                    <MainButton disabled={(showLegalRepresentative && preleveur.structureType !== DECLARATION_STRUCTURE_TYPE.INDIV_STRUCT)} noFullWidth sx={{ width: 'auto' }} onClick={ () => {
                        setEditMode(false)
                        dispatch(AgriAction.updateDeclaration({ ...declaration, lastStep: DECLA_LAST_STEP.POINTS_STEP, statusCode: DECLARATION_STATUS.ONGOING })).then(() => {
                            dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation))
                        })
                    }}
                    >
                        {i18n.validContacts}
                    </MainButton>
                ) : (declaration.statusCode !== DECLARATION_STATUS.SENT && (
                    <MainButton noFullWidth sx={{ width: 'auto' }} onClick={ () => {
                        setEditMode(true)
                        dispatch(AgriAction.updateDeclaration({ ...declaration, lastStep: DECLA_LAST_STEP.CONTACTS_STEP, statusCode: DECLARATION_STATUS.ONGOING })).then(() => {
                            dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then(() => {
                                setValidationStep(false)
                            })
                        })
                    }}
                    >
                        {i18n.changeContacts}
                    </MainButton>
                ))}
            </Grid2>
            {openModal && <ModalContact isOpen={openModal} onClose={(() => setOpenModal(false))} showLegalRepresentative={showLegalRepresentative} onSave={onSave} onDelete={onDelete}/>}
        </Grid2>
    )
}

ContactsValidation.propTypes = {
    setValidationStep: PropTypes.func
}

export default ContactsValidation