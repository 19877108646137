'use strict'
import {
    RECEIVE_MAT_EVENTS_EXPLOITATION,
    RECEIVE_MAT_EVENTS_TYPE,
} from '../constants/MaterielConstants'
import { FULL_RESET } from '../../../../offline/constants/HomeConstants'
import DtoMatEvent from '../dto/DtoMatEvent'
import DtoMatEventsType from '../dto/DtoMatEventsType'

export const store = {
    matEventsExploitation: [],
    matEventsTypes: [],
}

export function MaterielReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_MAT_EVENTS_EXPLOITATION:
            return {
                ...state,
                matEventsExploitation: action.matEventsExploitation.map((e) => new DtoMatEvent(e)),
            }
        case RECEIVE_MAT_EVENTS_TYPE:
            return {
                ...state,
                matEventsTypes: action.matEventsTypes.map((t) => new DtoMatEventsType(t)),
            }
        case FULL_RESET:
            return {
                ...store,
            }
        default:
            return state
    }
}
