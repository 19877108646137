import React from 'react'
import PropTypes from 'prop-types'
import { Grid2, useTheme } from '@mui/material'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../../utils/DateUtil'
import DtoCasingExploit from '../../agri/dto/exploitation/DtoCasingExploit'
import DtoCasingDeclaration from '../../agri/dto/enquete/DtoCasingDeclaration'
import { LightCard } from '../styled/grid'
import { instanceOf } from '../../../../utils/StoreUtils'

const OuvrageCardDesktop = ({ title, ouvrage = {}, size=12 }) => {
    const theme = useTheme()

    return (
        <LightCard
            container
            size={size}
            direction='column'
            sx={{
                padding: theme.spacing(4)
            }}
        >
            <Grid2 fontSize={22} sx={{ fontWeight: 'regular' }}>{title ||i18n.struct}</Grid2>
            {ouvrage?.receiptDate ?
                (
                    <Grid2 container direction='column' sx={{ paddingTop: theme.spacing(3) }}>
                        <Grid2 container justifyContent='space-between'>
                            <Grid2>{i18n.depth}</Grid2>
                            <Grid2 sx={{ fontWeight: 'medium' }}>{ouvrage?.depth || '-'}</Grid2>
                        </Grid2>
                        <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                            <Grid2>{i18n.receiptNum}</Grid2>
                            <Grid2 sx={{ fontWeight: 'medium' }}>{ouvrage?.receiptNumber || '-'}</Grid2>
                        </Grid2>
                        <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                            <Grid2>{i18n.receiptDate}</Grid2>
                            <Grid2 sx={{ fontWeight: 'medium' }}>{ouvrage?.receiptDate ? getDate(ouvrage.receiptDate) : '-'}</Grid2>
                        </Grid2>
                        <Grid2 container justifyContent='space-between' sx={{ paddingTop: theme.spacing(2) }}>
                            <Grid2>{i18n.reserveFlow}</Grid2>
                            <Grid2 sx={{ fontWeight: 'medium' }}>{ouvrage?.reserveFlow || '-'}</Grid2>
                        </Grid2>
                    </Grid2>
                )
                :
                <Grid2>{i18n.noStruct}</Grid2>
            }

        </LightCard>
    )
}

OuvrageCardDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    ouvrage: instanceOf(DtoCasingDeclaration || DtoCasingExploit).isRequired,
    size: PropTypes.number
}

export default OuvrageCardDesktop
