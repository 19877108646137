/* eslint-disable camelcase */
import { CircularProgress, Grid2, TextField, styled } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { darkRed, mainColor, mainRed, veryLightColor } from './styled/theme'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../utils/DateUtil'
import moment from 'moment'
import PropTypes from 'prop-types'
import { isNil, orderBy, template, toInteger } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AgriAction from '../agri/actions/AgriAction'
import ToastrAction from './toasters/ToastrAction'
import { CHRONICLES_CONSTANTS, ENTRY_SETTINGS_FREQUENCY, RESTRICTION_RESOURCE_TYPE } from '../agri/constants/AgriConstants'
import { formatMilliers } from '../../../utils/StringUtil'
import { getEvolValue } from '../../../utils/AgriUtils'
import { DeleteOutlined, Error } from '@mui/icons-material'
import { MainButton } from './styled/buttons'
import { DialogContentMUIDesktop, DialogContentTextMUI, DialogMUI, DialogTitleMUIv2 } from './styled/dialog'
import useApplicationSetting from '../../../utils/customHooks/useApplicationSetting'
import { convertToRGB } from '../../../utils/ColorUtil'
import { instanceOf } from '../../../utils/StoreUtils'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'

const Line = styled(Grid2)({
    padding: '0 8px',
})

const ModalIndexEntry = ({
    material,
    point,
    openModalNewIndex,
    setOpenModalNewIndex,
    setIndexedPoint= () => {},
    getMaterial,
}) => {
    const [chroniclesLoaded, setChroniclesLoaded] = useState(true)

    const {
        exploitation,
        droughtSectorsRestrictions,
        managementUnitsRestrictions,
        waterTurnsRestrictions,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
    }), shallowEqual)

    const consoType = point.consoType || (point.estim ? CHRONICLES_CONSTANTS.TYPE_ESTIM : CHRONICLES_CONSTANTS.TYPE_INDEX)
    const isEstim = consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM
    const mat = material || getMaterial(point.idMatLastChronicle)

    const filteredChronicles = exploitation.link_chronicles.filter((c) => c.idMat === mat.id && c.mode !== 'd' && c.measureType === consoType)
    const orderedChronicles = orderBy(filteredChronicles, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
    const readingCoefficient = mat?.counter?.readingCoefficient || 1

    const [measureDate, setMeasureDate] = useState(isEstim ? getDate(moment(orderedChronicles[0]?.endDate), 'YYYY-MM-DD') : getDate(moment().valueOf(), 'YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(isEstim ? getDate(moment().valueOf(), 'YYYY-MM-DD') : '')
    const [value, setValue] = useState('')

    const getLabelHistory = () => (<span><b>{i18n.lastInput} :</b> {orderedChronicles.length ? `${formatMilliers(orderedChronicles[0].value)} ${i18n.m3} ${i18n.the} ${isEstim ? moment(orderedChronicles[0].endDate).format('DD/MM/YYYY') : moment(orderedChronicles[0].measureDate).format('DD/MM/YYYY')}` : i18n.noHistory}</span>)

    const dispatch = useDispatch()

    const appSettingEntryFrequency = useApplicationSetting('entryFrequency', res => toInteger(res))
    const appSettingEntryFrequencyDay = useApplicationSetting('entryFrequencyDay', res => toInteger(res))
    const typeRestriction = useApplicationSetting('agriTypeRestriction') ?? 1
    const restriction = useMemo(() => {
        if (typeRestriction === RESTRICTION_RESOURCE_TYPE.DROUGHT_SECTORS) {
            return droughtSectorsRestrictions.find((dsR) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType) || { idRestriction: -1 }
        }
        return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.subManagementCode && point.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.managementCode && point.sampleType === ugeR.resourceType) || { idRestriction: -1 }
    }, [typeRestriction])
    const waterTurnsRestriction = useMemo(() => waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction), [restriction])
    const entryFrequency = useMemo(() =>
        waterTurnsRestriction?.entryFrequency
        ?? appSettingEntryFrequency
        ?? undefined, [waterTurnsRestriction, appSettingEntryFrequency])
    const entryFrequencyDay = useMemo(() =>
        waterTurnsRestriction?.entryFrequencyDay
        ?? appSettingEntryFrequencyDay
        ?? undefined, [waterTurnsRestriction, appSettingEntryFrequencyDay])
    const blockConsumptionsDeletion = useApplicationSetting('blockConsumptionsDeletion', res => res === '1')
    const blockEntryDate = useApplicationSetting('blockEntryDate', res => res === '1')

    const delaySinceLastEntry = useMemo(() => {
        if (entryFrequency === ENTRY_SETTINGS_FREQUENCY.WEEKLY) {
            return isEstim ?
                moment().diff(moment(moment(orderedChronicles[0]?.endDate).day(7 + entryFrequencyDay)), 'days')
                :
                moment().diff(moment(moment(orderedChronicles[0]?.measureDate).day(7 + entryFrequencyDay)), 'days')
        }
        return isEstim ?
            moment().diff(moment(moment(orderedChronicles[0]?.endDate).date(moment(orderedChronicles[0]?.endDate).daysInMonth() + entryFrequencyDay)), 'days')
            :
            moment().diff(moment(moment(orderedChronicles[0]?.measureDate).date(moment(orderedChronicles[0]?.measureDate).daysInMonth() + entryFrequencyDay)), 'days')
    }, [orderedChronicles])

    const addChronicle = () => {
        if (measureDate && !isNil(value)) {
            const link_chronicles = [
                ...exploitation.link_chronicles,
                {
                    matType: 'divers',
                    idMat: mat.id,
                    measureType: consoType,
                    measureDate: new Date(measureDate).getTime(),
                    value,
                    endDate: isEstim ? new Date(endDate).getTime() : undefined,
                    idChronicle: undefined,
                    new: true,
                }
            ]
            const updateExploitation = {
                ...exploitation,
                link_chronicles,
            }
            setChroniclesLoaded(false)
            dispatch(AgriAction.updateExploitation(updateExploitation)).then(() => {
                ToastrAction.success(i18n.entryValidated)
                dispatch(AgriAction.fetchExploitation()).then(() => setChroniclesLoaded(true))
            })
            setMeasureDate(isEstim ? endDate : null)
            setValue('')
            setEndDate('')
        } else {
            ToastrAction.error(i18n.fillAllFields)
        }
    }

    const removeChronicle = (idChronicle) => {
        const link_chronicles = [
            ...exploitation.link_chronicles.filter((c) => c.idChronicle !== idChronicle),
            {
                ...exploitation.link_chronicles.find((c) => c.idChronicle === idChronicle),
                mode: 'd',
            }
        ]
        const updateExploitation = {
            ...exploitation,
            link_chronicles,
        }
        setChroniclesLoaded(false)
        dispatch(AgriAction.updateExploitation(updateExploitation)).then(() => {
            ToastrAction.success(i18n.entryDeleted)
            dispatch(AgriAction.fetchExploitation()).then(() => setChroniclesLoaded(true))
        })
    }

    return (
        <DialogMUI open={openModalNewIndex}
            keepMounted
            onClose={() => {
                setOpenModalNewIndex(false)
                setIndexedPoint({})
            }}
        >
            <DialogTitleMUIv2
                onClick={() => {
                    setOpenModalNewIndex(false)
                    setIndexedPoint({})
                }}
            >
                {point.name || i18n.pointPrelevement } - {point.code}
            </DialogTitleMUIv2>
            <DialogContentMUIDesktop>
                <Grid2 container size={12} spacing={2} sx={{ marginRight: '5px' }}>
                    <DialogContentTextMUI style={{ alignSelf: 'flex-start', width: '100%' }}>
                        {mat?.counter ? i18n.counter : i18n.pump} <b>{mat?.reference ?? ''}</b> - {i18n.coefLectureShort} : &nbsp;<b>{readingCoefficient}</b>
                    </DialogContentTextMUI>
                    {delaySinceLastEntry > 0 &&
                        <Grid2
                            container
                            size={12}
                            columnSpacing={1}
                            alignItems='center'
                            flexWrap='nowrap'
                            sx={{
                                border: `1px solid ${darkRed}`,
                                borderRadius: '5px',
                                backgroundColor: `rgba(${convertToRGB(mainRed)}, 0.1)`,
                                padding: '8px 5px',
                                color: darkRed,
                                fontSize: '14px',
                            }}
                        >
                            <Grid2 container size={'auto'}><Error /></Grid2>
                            <Grid2 container size={'grow'}>{template(isEstim ? i18n.noEstimSinceXDays : i18n.noIndexSinceXDays)({ days: delaySinceLastEntry })}</Grid2>
                        </Grid2>
                    }
                    <Grid2 container size={12} gap={1}>
                        <Grid2 size={6}>
                            <TextField
                                id='startDate'
                                label={isEstim ? i18n.startDate : i18n.statementDate}
                                type='date'
                                required
                                fullWidth
                                variant='outlined'
                                value={measureDate}
                                disabled={blockEntryDate && (isEstim ? orderedChronicles[0]?.endDate : true)}
                                slotProps={{
                                    htmlInput: {
                                        max: endDate || '9999-12-31'
                                    },
                                    inputLabel: {
                                        shrink: true,
                                    }
                                }}
                                onChange={(e) => setMeasureDate(e.target.value)}
                            />
                        </Grid2>
                        {!!isEstim && (
                            <Grid2 container size={6}>
                                <TextField
                                    id='endDate'
                                    label={i18n.endDate}
                                    type='date'
                                    fullWidth
                                    slotProps={{
                                        htmlInput: {
                                            min: measureDate,
                                            max: '9999-12-31'
                                        },
                                        inputLabel: {
                                            shrink: true,
                                        }
                                    }}
                                    required
                                    variant='outlined'
                                    value={endDate}
                                    disabled={blockEntryDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Grid2>
                        )}
                    </Grid2>
                    <Grid2 container size={12}>
                        <TextField
                            id='value'
                            label={isEstim ? i18n.estim : i18n.index}
                            type='number'
                            value={value}
                            onChange={(e) => setValue(parseInt(e.target.value))}
                            variant='outlined'
                            sx={{ width: '100%' }}
                            required
                        />
                    </Grid2>
                    <Grid2 container size={12} sx={{ flexWrap: 'nowrap', alignItems: 'center' }}>
                        <Grid2 container size={12} justifyContent='flex-start' fontSize={14} sx={{ lineHeight: '20px', }}>
                            {chroniclesLoaded && getLabelHistory()}
                        </Grid2>
                        <Grid2 container size={12} justifyContent='flex-end'>
                            <MainButton noFullWidth sx={{ width: '6vw' }} disabled={!chroniclesLoaded || isNil(value)} onClick={() => addChronicle()}>
                                {i18n.add}
                            </MainButton>
                        </Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='flex-start' fontSize={22} sx={{ lineHeight: '28px' }}>{i18n.historical}</Grid2>
                    <Grid2
                        container
                        size={12}
                        alignContent='flex-start'
                        justifyContent='center'
                        sx={{ color: mainColor, height: '100%' }}
                    >
                        <Line size={12} container>
                            {isEstim ? (
                                <>
                                    <Grid2 className='bold' size={3} sx={{ textAlign: 'start' }}>
                                        {i18n.startDate }
                                    </Grid2>
                                    <Grid2 className='bold' size={3} sx={{ textAlign: 'start' }}>
                                        {i18n.endDate }
                                    </Grid2>
                                    <Grid2 className='bold' size={3} sx={{ textAlign: 'end' }}>
                                        {i18n.estim }
                                    </Grid2>
                                    <Grid2 className='bold' size={2} sx={{ textAlign: 'end' }}>
                                        {i18n.accumulationM3}
                                    </Grid2>
                                    <Grid2 className='bold' size={1} sx={{ textAlign: 'end' }} />
                                </>
                            ) : (
                                <>
                                    <Grid2 className='bold' size={3} sx={{ textAlign: 'start' }}>
                                        {i18n.statementDate}
                                    </Grid2>
                                    <Grid2 className='bold' size={3} sx={{ textAlign: 'end' }}>
                                        {i18n.index}
                                    </Grid2>
                                    <Grid2 className='bold' size={4} sx={{ textAlign: 'end' }}>
                                        {`${i18n.evolution} (m3)`}
                                    </Grid2>
                                    <Grid2 className='bold' size={1} sx={{ textAlign: 'end' }}>{' '}</Grid2>
                                </>
                            )}
                        </Line>
                        <Grid2 className size={12} sx={{ borderBottom: '1px solid black' }} />
                        <Grid2 container sx={{ overflowY: 'auto', height: '200px' }} >
                            {chroniclesLoaded ? orderedChronicles.map((chronicle, i) => {
                                const backgroundColor = i % 2 ? veryLightColor : 'white'
                                const valueEvol = (i !== (filteredChronicles.length - 1)) && getEvolValue(consoType, orderedChronicles, chronicle, i)
                                if (consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM) {
                                    return (
                                        <Line size={12} container>
                                            <Grid2 size={3} sx={{ textAlign: 'start', paddingLeft: 0 }}>
                                                {getDate(chronicle.measureDate, 'DD/MM')}
                                            </Grid2>
                                            <Grid2 size={3} sx={{ textAlign: 'start' }}>
                                                {chronicle.endDate ? getDate(chronicle.endDate, 'DD/MM') : ''}
                                            </Grid2>
                                            <Grid2 size={3} sx={{ textAlign: 'end' }}>
                                                {`${formatMilliers(chronicle.value) || 0} m3`}
                                            </Grid2>
                                            <Grid2 size={2} sx={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                                {!isNil(valueEvol) ? formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0)) : ''}
                                            </Grid2>
                                            {!blockConsumptionsDeletion &&
                                                <Grid2 size={1} container justifyContent='flex-end'>
                                                    <DeleteOutlined className='clickable' onClick={() => removeChronicle(chronicle.idChronicle)} />
                                                </Grid2>
                                            }
                                        </Line>
                                    )
                                }
                                return (
                                    <Line size={12} container alignItems='center' sx={{ backgroundColor }}>
                                        <Grid2 size={3} sx={{ textAlign: 'start', paddingLeft: 0 }}>
                                            {getDate(chronicle.measureDate)}
                                        </Grid2>
                                        <Grid2 size={3} sx={{ textAlign: 'end' }}>
                                            {formatMilliers(chronicle.value) || 0}
                                        </Grid2>
                                        <Grid2 size={4} sx={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                            {!isNil(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                        </Grid2>
                                        {!blockConsumptionsDeletion &&
                                            <Grid2 size={1} container justifyContent='flex-end'>
                                                <DeleteOutlined className='clickable' onClick={() => removeChronicle(chronicle.idChronicle)} />
                                            </Grid2>
                                        }
                                    </Line>
                                )
                            }) : <Grid2 size={12} container justifyContent='center'><CircularProgress /></Grid2>}
                        </Grid2>
                    </Grid2>
                </Grid2>
            </DialogContentMUIDesktop>
        </DialogMUI>
    )
}

ModalIndexEntry.propTypes = {
    material: PropTypes.shape({}),
    point: instanceOf(DtoInstallation),
    openModalNewIndex: PropTypes.bool,
    setOpenModalNewIndex: PropTypes.func,
    setIndexedPoint: PropTypes.func,
    getMaterial: PropTypes.func,
}

export default ModalIndexEntry