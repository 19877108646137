import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid2, Card } from '@mui/material'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import CmsAction from '../cms/actions/CmsAction'
import { mainColor } from '../components/styled/theme'
import ContactAction from '../contact/actions/ContactAction'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { getDate } from '../../../utils/DateUtil'
import { getSettingInt } from '../../../utils/FormUtils'
import { convertToRGB } from '../../../utils/ColorUtil'
import { SAMPLE_TYPES } from '../agri/constants/AgriConstants'
import LoadingCard from '../components/cards/LoadingCard'
import { Add, Remove } from '@mui/icons-material'
import WaterTurnsWeekCalendar from '../components/calendar/WaterTurnsWeekCalendar'
import { TextButton } from '../components/styled/buttons'
import useProgressDispatch from '../../../utils/customHooks/useProgressDispatch'
import { useParams } from 'react-router'

const PointRestrictionDesktop = ({ sx }) => {
    const [showExemption, setShowExemption] = useState(false)
    const params = useParams()

    const idInstallation = parseInt(params.id)
    const year = new Date().getFullYear()

    const {
        installation,
        exploitation,
        managementUnitsRestrictions,
        waterTurnsRestrictions,
        waterTurnsSlots,
        exploitationWaterTurns,
        droughtSectorsRestrictions,
        applicationSettings,
    } = useSelector(store => ({
        installation: store.InstallationsReducer.installation,
        exploitation: store.AgriReducer.exploitation,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
    }))

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => {
        const exploitationPromise = !exploitation.idExploitation ? [
            dispatch(AgriAction.fetchExploitation())
        ] : []

        return [
            dispatch(CmsAction.fetchCmsByCategory(5)),
            dispatch(ReferencialAction.fetchManagementUnitsRestrictions()),
            dispatch(AgriAction.fetchDroughtSectorsRestrictions()),
            dispatch(ContactAction.fetchContacts()),
            dispatch(InstallationsAction.fetchInstallation(idInstallation)),
            ...exploitationPromise,
        ]
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getRestriction = (type, sampleCharacteristics) => {
        if (type === 1) {
            return droughtSectorsRestrictions.find((dsR) => dsR.idSector === sampleCharacteristics.droughtSector && sampleCharacteristics.sampleType === dsR.resourceType) || { idRestriction: -1 }
        }
        return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.subManagementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.managementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || { idRestriction: -1 }
    }

    if (isLoaded) {
        const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
        const sampleCharacteristics = installation?.link_sampleCharacteristics[0] || {}
        const restriction = getRestriction(typeRestriction, sampleCharacteristics)
        const waterTurnRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)

        const data = exploitationWaterTurns.filter((w) => w.idExploitation === exploitation.idExploitation && w.idInstallation === installation.id && w.year === year)

        return (
            <Grid2 container size={12} direction='column' sx={{ color: mainColor, ...sx }}>
                {
                    (restriction && waterTurnRestriction) && (
                        <Card sx={{ backgroundColor: 'white', marginBottom: '24px', borderRadius: '15px', boxShadow: 'none' }}>
                            <Grid2 container size={12} direction='column' alignItems='space-between' justifyContent='center' sx={{ backgroundColor: `rgba(${convertToRGB(waterTurnRestriction.color)}, 0.3)`, padding: '16px 16px', paddingBottom: restriction.exemption ? '8px' : '16px', borderRadius: '15px' }}>
                                <Grid2 sx={{ padding: '8px 0', fontSize: '12px', fontWeight: 'medium', color: mainColor }}>{getDate(restriction.updateDate)}</Grid2>
                                <Grid2 sx={{ fontSize: '16px', fontWeight: 'medium', color: mainColor }}>{`${waterTurnRestriction.label} - ${waterTurnRestriction.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial} - ${waterTurnRestriction.description}`}</Grid2>
                                <Grid2 sx={{ fontSize: '16px', paddingTop: '8px', color: mainColor }}>{waterTurnRestriction.comment || ''}</Grid2>
                                <Grid2 sx={{ fontSize: '16px', paddingTop: '8px', color: mainColor }}>{showExemption ? restriction.exemption || '' : ''}</Grid2>
                                {restriction.exemption &&
                                    <Grid2 container justifyContent='flex-end'>
                                        <TextButton sx={{ margin: 0 }} onClick={() => setShowExemption(!showExemption)} noFullWidth startIcon={showExemption ? <Remove /> : <Add />}>
                                            {showExemption ? i18n.showLess : i18n.showMore}
                                        </TextButton>
                                    </Grid2>
                                }
                            </Grid2>
                        </Card>
                    )
                }
                {data.map((d) => <WaterTurnsWeekCalendar waterTurn={d} waterTurnsSlots={waterTurnsSlots} waterTurnsRestrictions={waterTurnsRestrictions} />)}
                {!data?.length && i18n.noWaterTurnDescription}
            </Grid2>
        )
    }
    return <LoadingCard sx={sx} progress={progress} />
}

PointRestrictionDesktop.propTypes = {
    sx: PropTypes.shape({}),
}

export default PointRestrictionDesktop
