module.exports = {
    RECEIVE_MAT_EVENTS_EXPLOITATION: 'RECEIVE_MAT_EVENTS_EXPLOITATION',
    RECEIVE_MAT_EVENTS_TYPE: 'RECEIVE_MAT_EVENTS_TYPE',
    SITU_POINT_PUMP: 7,
    SITU_PUMP_COUNTER: 8,
    LINK_TYPE: {
        REPART_PUMP: 1,
        REPART_COUNTER: 2,
    },
}
