import i18n from 'simple-react-i18n'
import {
    RECEIVE_CMS_EVENTS,
    RECEIVE_CMS_CATEGORY,
    RECEIVE_CMS_ACTUALITIES,
} from '../constants/CmsConstants'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { aquaFetchV2, checkAuth, checkError, getAuthorization, getAuthorizationLogin } from '../../../../utils/ActionUtils'
import ToastrAction from '../../components/toasters/ToastrAction'
import LogAction from '../../../../utils/log/actions/LogAction'

const CmsAction = {
    receiveCMSEvents(cmsEvents) {
        return { type: RECEIVE_CMS_EVENTS, cmsEvents }
    },

    promiseCMSEvents() {
        return fetch(ApplicationConf.cms.getAll(), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchCMSEvents(page) {
        return (dispatch) => {
            return CmsAction.promiseCMSEvents()
                .then((json = []) => {
                    dispatch(CmsAction.receiveCMSEvents(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.articles} : ${err}`)
                    if (page !== 'dashboard') {
                        ToastrAction.error(i18n.fetchError + i18n.articles)
                    }
                })
        }
    },

    receiveCmsActualities(actualities) {
        return { type: RECEIVE_CMS_ACTUALITIES, actualities }
    },

    fetchCmsActualities() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.cms.getByCategory(6), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().CmsReducer.actualities })
                .then((json = []) => {
                    dispatch(CmsAction.receiveCmsActualities(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },

    receiveCmsCateg(cms) {
        return { type: RECEIVE_CMS_CATEGORY, cms }
    },
    fetchCmsByCategory(id) {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.cms.getByCategory(id), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().CmsReducer.cms })
                .then((json) => {
                    dispatch(CmsAction.receiveCmsCateg(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError} : ${err}`)
                })
        }
    },
}

export default CmsAction
