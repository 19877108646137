export default class DtoMapSituationResult {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.code
        this.name = obj.name
        this.city = obj.city
        this.townCode = obj.townCode
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.trendLabel = obj.trendLabel
        this.trendColor = obj.trendColor
        this.indicatorDate = obj.indicatorDate
        this.indicatorValue = obj.indicatorValue
        this.lastMeasureDate = obj.lastMeasureDate
        this.lastMeasureValue = obj.lastMeasureValue
        this.measureValue = obj.measureValue
        this.thresholdName = obj.thresholdName
        this.thresholdValue = obj.thresholdValue
        this.comment = obj.comment
    }
}