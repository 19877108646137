import React from 'react'
import { TextField, FormControl, styled } from '@mui/material'
import { mainColor } from './theme'
// import { screenColor } from './theme'

export const Input = styled(TextField)({
    width: '100%',
    '& label.Mui-focused': {
        color: mainColor,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: mainColor,
    },
})

export const InputRow = styled(TextField)({
    margin: '5px 0',
    width: '100% !important',
    zIndex: '0',
})

export const InputMUI = styled(({ sx, ...otherProps }) => (
    <TextField
        sx={{
            width: otherProps.noFullWidth ? otherProps.width : '100%',
            ...sx,
        }}
        {...otherProps}
    />
))({})

export const FormControlRow = styled(FormControl)({
    margin: '5px 0',
    width: '100%',
})