import PropTypes from 'prop-types'

const createIndex = (tab, keyToUse) => {
    const res = {}
    if (keyToUse) {
        if (tab.length) {
            tab.forEach(o => {
                res[o[keyToUse]] = o
            })
            return res
        }
        return {}
    }
    if (tab.length) {
        tab.forEach(o => {
            res[o.id] = o
        })
        return res
    }
    return {}
}

const removeNullKeys = (obj) => {
    return Object.keys(obj).filter(k => !(obj[k] === undefined || obj[k] === null)).reduce((acc, key) => {
        const r = {}
        r[key] = obj[key]
        return Object.assign({}, acc, r)
    }, {})
}

const getSandreList = (sandreCodes, field) => {
    return sandreCodes.filter(c => c.field === field)
}

const arrayOf = (dto) => {
    return PropTypes.arrayOf(PropTypes.instanceOf(dto))
}

const instanceOf = (dto) => {
    return PropTypes.instanceOf(dto)
}

export { getSandreList, arrayOf, instanceOf, removeNullKeys, createIndex }