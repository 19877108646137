import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const useApplicationSetting = (field, parser = setting => setting) => {
    const {
        applicationSettings,
    } = useSelector(store => ({
        applicationSettings: store.HomeReducer.applicationSettings,
    }), shallowEqual)

    const res = useMemo(() => {
        const setting = applicationSettings?.find(s => s.parameter === field)
        return parser(setting?.value)
    }, [applicationSettings, field])
    return res
}

const booleanParser = setting => setting ? setting === '1' : undefined
const floatParser = setting => setting ? parseFloat(setting) : undefined
const intParser = setting => setting ? parseInt(setting) : undefined
const listStringParser = setting => setting?.split(',').filter(s => !!s) ?? []
const listIntParser = setting => setting?.split(',').filter(s => !!s).map(s => parseInt(s)) ?? []

export default useApplicationSetting
export {
    booleanParser,
    floatParser,
    intParser,
    listStringParser,
    listIntParser,
}