import React, { createRef, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Grid2, Input } from '@mui/material'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import { orderBy } from 'lodash'
import ToastrAction from '../components/toasters/ToastrAction'
import { formatPhone } from '../../../utils/StringUtil'
import { getLogin } from '../../../utils/UserUtils'
import HomeAction from '../../offline/actions/HomeAction'
import ContactAction from './actions/ContactAction'
import { mainColor, textColor, veryLightColor } from '../components/styled/theme'
import { getDate, getDateAndHour, getHourAndMinute } from '../../../utils/DateUtil'
import { ArrowCircleLeft } from '@mui/icons-material'
import LoadingCard from '../components/cards/LoadingCard'
import moment from 'moment'

const MessagesApp = () => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [message, setMessage] = useState('')
    const endMessages = createRef()

    const {
        listOfMessages,
        contacts,
        usersLight,
        exploitation,
        contributor,
    } = useSelector(store => ({
        listOfMessages: store.ContactReducer.listOfMessages,
        contacts: store.ContactReducer.contacts,
        usersLight: store.HomeReducer.usersLight,
        exploitation: store.AgriReducer.exploitation,
        contributor: store.ReferencialReducer.contributor,
    }), shallowEqual)

    const scrollToBottom = () => {
        endMessages.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const dispatch = useDispatch()

    const getAllMessages = () => {
        dispatch(ContactAction.getMessagesOf(getLogin())).then(() => {
            setDataLoaded(true)
        })
    }


    useEffect(() => {
        if (!contacts.length) {
            dispatch(ContactAction.fetchContacts())
        }
        if (!usersLight.length) {
            dispatch(HomeAction.fetchUsersLight())
        }
        getAllMessages()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            getAllMessages()
        }, 30000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [listOfMessages, dataLoaded])


    const handleChangeMessage = (e) => {
        const value = e.target.value
        setMessage(value)
    }

    const sendMessage = () => {
        if (message) {
            const login = getLogin()
            const userMessage = { login, message }
            dispatch(ContactAction.sendMessage(userMessage)).then(() => {
                getAllMessages()
                setMessage('')
            })
        } else {
            ToastrAction.warning(i18n.noMessagesToSend)
        }
    }

    if (!dataLoaded) {
        return <LoadingCard />
    }
    const date = getDate(moment().valueOf())
    // TODO SPACING
    return (
        <Grid2 container size={12} justifyContent={'center'} sx={{ height: '100%' }}>
            <Grid2 container size={12} paddingBottom='4vh' color={textColor} sx={{ fontSize: '22px', lineHeight: '28px', letterSpacing: '0.5px' }}>
                {i18n.messages}
            </Grid2>
            <Grid2 container size={12} paddingBottom='4vh' color={textColor} sx={{ fontSize: '16px', lineHeight: '24px' }}>
                {contributor.internalIdentifier || contributor.name} - {exploitation.codification}
            </Grid2>
            <Grid2 container size={7} sx={{ height: '70%' }}>
                <Grid2 container size={12} alignContent={'flex-start'} sx={{ paddingRight: '2vh', height: '100%', overflowY: 'auto', paddingBottom: '2vh' }}>
                    {orderBy(listOfMessages, 'updateDate').map((m) => {
                        const user = usersLight.find((u) => u.login === m.updateLogin) || {}
                        const contact = contacts.find((c) => c.id === user.contactCode) || {}
                        const phone = contact.phoneTel ? `${formatPhone(contact.phoneTel)} - ` : ''
                        const mobile = contact.mobile ? `${formatPhone(contact.mobile)}` : ''
                        return (
                            <Grid2
                                container
                                size={12}
                                direction='column'
                                alignItems={m.updateLogin === m.login ? 'flex-end' : 'flex-start'}
                            >
                                <Paper
                                    sx={{
                                        background: 'none',
                                        boxShadow: 'none',
                                        color: 'grey',
                                        fontSize: '12px',
                                    }}
                                >
                                    {date === getDate(m.updateDate) ? getHourAndMinute(m.updateDate) : getDateAndHour(m.updateDate)}
                                </Paper>
                                <Grid2
                                    container
                                    size={9}
                                    sx={{
                                        backgroundColor: m.updateLogin === m.login ? veryLightColor : mainColor,
                                        padding: '10px 16px',
                                        margin: '10px 0 10px 0',
                                        borderRadius: '22px',
                                    }}
                                    direction='column'
                                    alignItems='flex-start'
                                    justifyContent='flex-end'
                                >
                                    {m.updateLogin !== m.login && (
                                        <>
                                            <div className='bold padding-bottom-2' style={{ color: '#FFF' }}>{`${m.updateLogin} ${contact?.name ? `(${contact?.name})` : ''}`}</div>
                                            <div className='padding-bottom-2'>
                                                {contact.phoneTel || contact.mobile ? <span style={{ fontSize: '14px', fontStyle: 'italic', color: '#FFF' }}>{phone} {mobile}<br/></span> : null}
                                                {contact.email ? <span style={{ fontSize: '14px', fontStyle: 'italic', color: veryLightColor }}>{contact.email}</span> : null}
                                            </div>
                                        </>
                                    )}
                                    <Paper sx={{ background: 'none', boxShadow: 'none', whiteSpace: 'pre-wrap', color: m.updateLogin === m.login ? 'black' : 'white', width: '100%' }}>
                                        {m.message}
                                    </Paper>
                                </Grid2>
                            </Grid2>
                        )
                    })}
                    <div ref={ endMessages }/>
                </Grid2>
            </Grid2>
            <Grid2 container size={7}>
                <Grid2 container size={12} sx={{ height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Input
                        id='renseignement'
                        type='text'
                        placeholder={i18n.anyInformation}
                        value={message}
                        onChange={handleChangeMessage}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                sendMessage()
                            }
                        }}
                        disableUnderline
                        sx={{ padding: '16px 20px', borderRadius: '32px', backgroundColor: veryLightColor, height: '100%', width: 'calc(100% - 56px)' }}
                    />
                    <IconButton
                        aria-label='toggle password visibility'
                        onClick={sendMessage}
                    >
                        <ArrowCircleLeft sx={{ fontSize: '40px', rotate: '90deg', color: mainColor }} />
                    </IconButton>
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

export default MessagesApp
