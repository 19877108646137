import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import HomeAction from '../offline/actions/HomeAction'
import { getLoginPassword, getPayload } from '../../utils/ActionUtils'

const checkAuth = (logout) => {
    const payload = getPayload()
    const logPass = getLoginPassword()
    if (!payload) {
        return false
    } else if (payload && logPass) {
        return true
    } else if (moment().isAfter(moment(JSON.parse(payload).exp))) {
        logout()
        return false
    }
    return false
}

const AuthRoute = ({ Component, ...props }) => {
    return checkAuth(props.logout) ? <Component {...props}/> : <Navigate to='/login' />
}

const mapDispatchToProps = {
    logout: HomeAction.logout,
}

AuthRoute.propTypes = {
    logout: PropTypes.func,
    Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

export default connect(null, mapDispatchToProps)(AuthRoute)
