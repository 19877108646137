/* eslint-disable no-process-env */
import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { createRouterMiddleware, createRouterReducer } from '@lagunovsky/redux-react-router'
import { createHashHistory } from 'history'
import { HomeReducer, store as HomeReducerStore } from '../pages/offline/reducers/HomeReducer'
import { AccountReducer, store as AccountReducerStore } from '../pages/online/account/reducers/AccountReducer'
import { ContactReducer, store as ContactReducerStore } from '../pages/online/contact/reducers/ContactReducer'
import { CityReducer, store as CityReducerStore } from '../pages/online/referencials/city/reducers/CityReducer'
import { InstallationsReducer, store as InstallationsReducerStore } from '../pages/online/referencials/installations/reducers/InstallationsReducer'
import { ReferencialReducer, store as ReferencialReducerStore } from '../pages/online/referencials/reducers/ReferencialReducer'
import { MaterielReducer, store as MaterielReducerStore } from '../pages/online/referencials/materiels/reducers/MaterielReducer'
import { AgriReducer, store as AgriReducerStore } from '../pages/online/agri/reducers/AgriReducer'
import { CmsReducer, store as CmsReducerStore } from '../pages/online/cms/reducers/CmsReducer'
import { FileReducer, store as FileReducerStore } from '../pages/online/referencials/documents/reducers/FileReducer'

export const history = createHashHistory()

const initialStore = {
    HomeReducer: HomeReducerStore,
    ContactReducer: ContactReducerStore,
    AccountReducer: AccountReducerStore,
    CityReducer: CityReducerStore,
    InstallationsReducer: InstallationsReducerStore,
    ReferencialReducer: ReferencialReducerStore,
    AgriReducer: AgriReducerStore,
    CmsReducer: CmsReducerStore,
    MaterielReducer: MaterielReducerStore,
    FileReducer: FileReducerStore,
}

const appReducers = combineReducers({
    HomeReducer,
    ContactReducer,
    AccountReducer,
    CityReducer,
    InstallationsReducer,
    ReferencialReducer,
    AgriReducer,
    CmsReducer,
    MaterielReducer,
    FileReducer,
    router: createRouterReducer(history),
})

const RootReducer = (state, action) => appReducers(state, action)

const loggerMiddleware = process.env.NODE_ENV === 'development' ? [logger] : []

const AppStore = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
        ...loggerMiddleware,
        createRouterMiddleware(history),
    ],
    devTools: process.env.NODE_ENV === 'development',
    preloadedState: initialStore,
})

export default AppStore