export default class DtoWaterTurn {
    constructor(obj) {
        this.id = obj.id // Option[Long],
        this.idExploitation = obj.idExploitation // Option[Long],
        this.idInstallation = obj.idInstallation // Option[Long],
        this.idMat = obj.idMat // Option[Long],
        this.day1 = obj.day1 // Option[String],
        this.day2 = obj.day2 // Option[String],
        this.day3 = obj.day3 // Option[String],
        this.day4 = obj.day4 // Option[String],
        this.day5 = obj.day5 // Option[String],
        this.day6 = obj.day6 // Option[String],
        this.day7 = obj.day7 // Option[String],
        this.comment = obj.comment // Option[String],
        this.idSlot = obj.idSlot // Option[Long],
        this.year = obj.year // Option[Int]

        // display data
        this.samplerName = obj.samplerName // Option[String],
        this.pumpType = obj.pumpType // Option[String],
        this.maxFlow = obj.maxFlow // Option[Double],
        this.material = obj.material // Option[String],
        this.serialNumber = obj.serialNumber // Option[String],
        this.nature = obj.nature // Option[String],
        this.mobility = obj.mobility // Option[String],
        this.identifier = obj.identifier // Option[String],
        this.samplingType = obj.samplingType // Option[Long],
    }
}