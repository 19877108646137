export default class DtoVariousMatSituation {
    constructor(obj) {
        this.idVariousSituation = obj.idVariousSituation // Int,
        this.siteCode = obj.siteCode // Option[Int],
        this.siteType = obj.siteType // Option[Int],
        this.siteName = obj.siteName // Option[String],
        this.idVarious = obj.idVarious // Option[Int],
        this.statusCode = obj.statusCode // Option[Int],
        this.comment = obj.comment // Option[String],
        this.situationDate = obj.situationDate // Option[DateTime],
        this.situationEndDate = obj.situationEndDate // Option[DateTime],
        this.campaignCode = obj.campaignCode // Option[Int],
        this.eventCode = obj.eventCode // Option[Int]
    }
}