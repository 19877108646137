import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionSummary, AccordionDetails, Grid2 } from '@mui/material'
import { mainColor, veryLightColor } from './styled/theme'
import { ExpandMore } from '@mui/icons-material'

const AccordeonDesktop = ({
    title,
    disabled,
    defaultExpanded,
    expanded,
    transparent,
    children,
    headerColor = veryLightColor,
    bodyColor,
    onChange = () => {},
    sx,
}) => (
    (children?.length ?? children) ?
        <Accordion
            disabled={disabled}
            defaultExpanded={defaultExpanded}
            expanded={expanded}
            disableGutters
            onChange={() => onChange()}
            sx={{
                boxShadow: 'none',
                color: mainColor,
                backgroundColor: 'transparent',
                ...sx,
                '& .Mui-expanded': {
                    'border-bottom-left-radius': 0,
                    'border-bottom-right-radius': 0,
                }
            }}
            slotProps={{
                transition: {
                    unmountOnExit: true,
                    timeout: 250,
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore style={{ color: mainColor }} />}
                sx={{ backgroundColor: transparent ? 'transparent' : headerColor, padding: '24px', borderRadius: '20px', alignItems: 'center' }}
            >
                <Grid2
                    container
                    size={12}
                    direction='column'
                    justifyContent='space-between'
                    flexWrap='nowrap'
                    className={children?.length ? 'clickable' : ''}
                    sx={{ fontWeight: 'medium' }}
                >
                    <Grid2 fontSize={22}>{title}</Grid2>
                </Grid2>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    backgroundColor: transparent ? 'transparent' : bodyColor,
                    padding: 0,
                    paddingBottom: children.length > 1 ? 0 : '20px',
                    borderRadius: '20px',
                    'border-top-left-radius': '0',
                    'border-top-right-radius': '0',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '20px',
                }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
        :
        <Grid2
            container
            size={12}
            alignItems='center'
            fontSize={22}
            className='bold'
            sx={{ backgroundColor: transparent ? 'transparent' : veryLightColor, padding: '24px', borderRadius: '20px', width: '100%', height: '99px' }}
        >
            {title}
        </Grid2>
)

AccordeonDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    defaultExpanded: PropTypes.bool,
    expanded: PropTypes.bool,
    transparent: PropTypes.bool,
    children: PropTypes.element,
    onChange: PropTypes.func,
    headerColor: PropTypes.string,
    bodyColor: PropTypes.string,
    sx: PropTypes.shape({}),
}

export default AccordeonDesktop