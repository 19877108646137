import { camelCase, lowerCase } from 'lodash'
import { hasValue } from './NumberUtil'

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const getI18nTitleDataLength = (i18nSingular, i18nPlurial = '', datasLength = 0) => {
    if (i18nPlurial) {
        if (datasLength > 1) {
            return i18nPlurial
        }
        return i18nSingular
    }
    return i18nSingular
}

const getI18nTitleData = (i18nSingular, i18nPlurial = '', datas = []) => {
    if (i18nPlurial) {
        if (datas) {
            return getI18nTitleDataLength(i18nSingular, i18nPlurial, datas.length)
        }
        return getI18nTitleDataLength(i18nSingular, i18nPlurial)
    }
    return i18nSingular
}

const searchAllCharacters = str => lowerCase(camelCase(str))

const getSandreLabel = (sandreCodes, field, code) => {
    const found = sandreCodes.find(c => c.field == field && c.code == code)
    return found ? found.name : null
}

const formatSiret = (siret) => {
    if (siret && siret.length === 14) {
        return `${siret.slice(0, 3)} ${siret.slice(3, 6)} ${siret.slice(6, 9)} ${siret.slice(9, siret.length)}`
    }
    return siret
}

const formatPhone = (phone) => {
    if (phone && phone.length === 10) {
        return `${phone.slice(0, 2)} ${phone.slice(2, 4)} ${phone.slice(4, 6)} ${phone.slice(6, 8)} ${phone.slice(8, phone.length)}`
    }
    return phone
}

const formatMilliers = (nb) => {
    const string = `${hasValue(nb) ? nb : ''}`
    const x = string.split('.')
    let x1 = x[0]
    const x2 = x.length > 1 ? `.${x[1]}` : ''
    const rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2')
    }
    return x1 + x2
}

export {
    searchAllCharacters,
    getSandreLabel,
    capitalizeFirstLetter,
    formatSiret,
    formatPhone,
    formatMilliers,
    getI18nTitleData,
    getI18nTitleDataLength,
}
