import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import {
    RECEIVE_DOCUMENTS_FILES,
    RECEIVE_DOCUMENTS_PICTURES,
} from '../constants/FileConstants'

import ApplicationConf from '../../../../../conf/ApplicationConf'
import {
    getAuthorization,
    getJson,
} from '../../../../../utils/ActionUtils'
import LogAction from '../../../../../utils/log/actions/LogAction'
import ToastrAction from '../../../components/toasters/ToastrAction'

const FileAction = {
    receivePictures(pictures) {
        return {
            type: RECEIVE_DOCUMENTS_PICTURES,
            pictures,
        }
    },
    fetchPictures(code, stationType) {
        return (dispatch) => {
            return fetch(ApplicationConf.picture.stationPicture(code, stationType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json = []) => {
                    dispatch(FileAction.receivePictures(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.pictures)
                })
        }
    },
    receiveFiles(files) {
        return {
            type: RECEIVE_DOCUMENTS_FILES,
            files,
        }
    },
    fetchFiles(code, stationType) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.stationFile(code, stationType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json = []) => {
                    dispatch(FileAction.receiveFiles(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`)
                    ToastrAction.error(i18n.fetchError + i18n.files)
                })
        }
    },
}

export default FileAction
