import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { MainButton } from '../components/styled/buttons'
import DtoEnquete from '../agri/dto/enquete/DtoEnquete'
import { DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from '../components/styled/dialog'
import { template } from 'lodash'
import { Grid2 } from '@mui/material'
import { instanceOf } from '../../../utils/StoreUtils'

const ModalEnqueteDesktop = ({ open, toggleModal, onClick, enquete = {} }) => (
    <DialogMUI open={open}>
        <DialogTitleMUIv2 onClick={toggleModal}>
            {`${i18n.enquete} ${enquete.year}`}
        </DialogTitleMUIv2>
        <DialogContentMUIDesktop>
            <Grid2>{template(i18n.surveyAvailableUntilX)({ date: new Date(enquete.endDate).toLocaleString() })}</Grid2>
            <Grid2><i>{i18n.contactAdmin}</i></Grid2>
            <Grid2 container justifyContent='center' spacing={2}>
                <MainButton reverse='true' noFullWidth onClick={toggleModal} sx={{ width: 'auto' }}>
                    {i18n.later}
                </MainButton>
                <MainButton noFullWidth onClick={onClick} sx={{ width: 'auto' }}>
                    {i18n.start}
                </MainButton>
            </Grid2>
        </DialogContentMUIDesktop>
    </DialogMUI>
)

ModalEnqueteDesktop.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    enquete: instanceOf(DtoEnquete),
}

export default ModalEnqueteDesktop