import React from 'react'
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import { LightCard } from '../styled/grid'
import { shallowEqual, useSelector } from 'react-redux'
import PointConsoDecla from '../../enquete/components/PointConsoDecla'

const ConsoCardDecla = (props) => {
    const {
        survey
    } = useSelector(store => ({
        survey: store.AgriReducer.survey
    }), shallowEqual())
    /*
    const date = moment().valueOf()
    const year = Number(moment(date).format('YYYY'))
    */
    return (
        <LightCard
            container
            size={12}
            sx={{
                padding: '3vh',
                rowGap: '2vh',
            }}
        >
            <Grid2 fontSize={22} className='bold' >
                {i18n.consos} {survey.surveyType === 1 ? survey.year - 1 : survey.year}
            </Grid2>
            <PointConsoDecla { ...props } />
        </LightCard>
    )
}

export default ConsoCardDecla