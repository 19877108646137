export default class DtoInstallationSample {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Long,
        this.sampleType = obj.sampleType // Option[Int],
        this.sampleNature = obj.sampleNature // Option[Int],
        this.bvCode = obj.bvCode // Option[Int],
        this.accompanyingAquiferCode = obj.accompanyingAquiferCode // Option[Int],
        this.lisaDbCode = obj.lisaDbCode // Option[Int],
        this.carthageDbCode = obj.carthageDbCode // Option[Int],
        this.topageDbCode = obj.topageDbCode // Option[Int],
        this.zre = obj.zre // Option[String],
        this.miocenezpr = obj.miocenezpr // Option[Boolean]
        this.codeZRE = obj.codeZRE // Option[Int],
        this.description = obj.description // Option[String],
        this.sandreCode = obj.sandreCode // Option[String],
        this.referentialCode = obj.referentialCode // Option[String]
        this.droughtSector = obj.droughtSector // Option[Int]
        this.volumeReference = obj.volumeReference // Option[Long]
    }
}
