import React from 'react'
import PropTypes from 'prop-types'
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import DtoExploitation from '../../online/agri/dto/exploitation/DtoExploitation'
import { MainButton } from '../../online/components/styled/buttons'
import { formatPhone } from '../../../utils/StringUtil'
import { DialogContentMUIDesktop, DialogMUI, DialogTitleMUIv2 } from '../../online/components/styled/dialog'
import { instanceOf } from '../../../utils/StoreUtils'

const ModalFolderDesktop = ({ open, exploitation, onCancel, onValidate }) => (
    <DialogMUI
        open={open}
        keepMounted
        onClose={onCancel}
    >
        <DialogTitleMUIv2 onClick={onCancel}>
            <Grid2 container justifyContent='space-between' alignItems='center'>
                <b style={{ padding: 10, fontSize: 21 }}>{exploitation.codification} - {exploitation.name || ''}</b>
            </Grid2>
        </DialogTitleMUIv2>
        <DialogContentMUIDesktop>
            <Grid2 container size={12} spacing={1}>
                <Grid2 container item size={12} direction='column' rowSpacing={1}>
                    <Grid2 item container justifyContent='space-between'>
                        <Grid2 item>{i18n.structure}</Grid2>
                        <Grid2 item className='bold'>{exploitation.structureType ?? '-'}</Grid2>
                    </Grid2>
                    <Grid2 item container justifyContent='space-between'>
                        <Grid2 item>{i18n.city}</Grid2>
                        <Grid2 item className='bold'>{`${exploitation.city ?? ''} [${exploitation.inseeCode ?? '-'}]`}</Grid2>
                    </Grid2>
                    <Grid2 item container justifyContent='space-between'>
                        <Grid2 item>{i18n.address}</Grid2>
                        <Grid2 item className='bold'>{exploitation.address ?? '-'}</Grid2>
                    </Grid2>
                    <Grid2 item container justifyContent='space-between'>
                        <Grid2 item>{i18n.phoneTel}</Grid2>
                        <Grid2 item className='bold'>{exploitation.phoneTel ? formatPhone(exploitation.phoneTel) : '-'}</Grid2>
                    </Grid2>
                    <Grid2 item container justifyContent='space-between'>
                        <Grid2 item>{i18n.phoneMobile}</Grid2>
                        <Grid2 item className='bold'>{exploitation.mobile ? formatPhone(exploitation.mobile) : '-'}</Grid2>
                    </Grid2>
                    <Grid2 item container justifyContent='space-between'>
                        <Grid2 item>{i18n.email}</Grid2>
                        <Grid2 item className='bold'>{exploitation.email ?? '-'}</Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 container item size={12} justifyContent='center'>
                    <MainButton onClick={onValidate} sx={{ width: 'auto' }}>
                        {i18n.accessFolder}
                    </MainButton>
                </Grid2>
            </Grid2>
        </DialogContentMUIDesktop>
    </DialogMUI>
)

ModalFolderDesktop.propTypes = {
    open: PropTypes.bool.isRequired,
    exploitation: instanceOf(DtoExploitation).isRequired,
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
}

export default ModalFolderDesktop