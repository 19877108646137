export default class DtoWaterTurnsSlot {
    constructor(obj) {
        this.id = obj.id // Option[Long] = None,
        this.year = obj.year // Option[Int] = None,
        this.comment = obj.comment // Option[String] = None,
        this.updateLogin = obj.updateLogin // Option[String] = None,
        this.updateDate = obj.updateDate // Option[DateTime] = None,
        this.slots = obj.slots // Option[String] = None

        // front
        this.nbSlots = obj.slots && obj.slots.split('/').length // Option[String] = None
    }
}