/* eslint-disable no-underscore-dangle */
import { isNil } from 'lodash'

const isValidSiret = (siret) => {
    if ((isNil(siret)) || (siret.length != 14)) {
        return false
    }
    const somme = siret.split('').reduce((sum, val, i) => i % 2 === 0 ? sum + (Number(val)*2 > 9 ? (Number(val)*2)-9 : Number(val)*2) : sum + Number(val), 0)
    if ((somme % 10) == 0) {
        return true
    }
    return false
}

const isValidZIPCode = (ZIPCode) => {
    const regex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/
    return regex.test(ZIPCode)
}

const isValidEmail = (email) => {
    // eslint-disable-next-line no-control-regex
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
    return regex.test(email)
}

const isValidPhone = (phone) => {
    const regex = /0[1-9]([0-9]{2}){4}/g
    return regex.test(phone)
}

const getSetting = (settingList, parameter) => {
    const found = settingList?.find(s => s.parameter === parameter)
    return found ? found.value : null
}

const getSettingInt = (settingList, parameter) => {
    const found = settingList?.find(s => s.parameter === parameter)
    return found && parseInt(found.value) ? parseInt(found.value) : null
}

export { isValidSiret, isValidZIPCode, isValidEmail, isValidPhone, getSetting, getSettingInt }
