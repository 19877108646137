module.exports = {
    RECEIVE_CMS_ACTUALITIES: 'RECEIVE_CMS_ACTUALITIES',
    RECEIVE_CMS_EVENTS: 'RECEIVE_CMS_EVENTS',
    RECEIVE_CMS_CATEGORY: 'RECEIVE_CMS_CATEGORY',
    CMS_STATUS: {
        PUBLISHED: 1,
        DRAFT: 2,
        ARCHIVED: 3,
    }
}
