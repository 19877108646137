import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid2 } from '@mui/material'
import DtoContact from '../../referencials/dto/DtoContact'
import { LightCard } from '../styled/grid'
import { EditOutlined } from '@mui/icons-material'
import { darkTextColor, textColor } from '../styled/theme'
import { shallowEqual, useSelector } from 'react-redux'
import { formatPhone } from '../../../../utils/StringUtil'
import ModalContact from '../../enquete/components/modals/ModalContact'
import LoadingCard from './LoadingCard'
import { instanceOf } from '../../../../utils/StoreUtils'

const ContactCardDesktop = ({
    contact,
    type,
    editMode,
    showLegalRepresentative,
    onSave = () => {},
    onDelete = () => {},
    defaultLegalRepresentative = false
}) => {
    const [openModal, setOpenModal] = useState(false)

    const {
        cities,
        citiesIndex,
    } = useSelector((store) => ({
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const labelStyle = { color: textColor, fontSize: '14px', lineHeight: '20px' }
    const valueStyle = { ...labelStyle, fontWeight: 500 }
    if ((!contact?.id && !contact?.idContributor && !contact?.idContributorTemp) || !type?.name || !cities.length) {
        return <LoadingCard />
    }
    const road = contact?.road
    const postalCode = contact?.postalCode
    const city = citiesIndex[contact?.cityCode]?.name
    const getAdress = () => {
        let address = ''

        if (road) address = `${address}${road}, `
        if (postalCode) address = `${address}${postalCode}, `
        if (city) address = `${address}${city}, `

        if (address) {
            return address.slice(0, -2)
        }
        return '-'
    }

    return (
        <LightCard
            container
            sx={{
                padding: '3vh',
                rowGap: '2vh',
                minHeight: '27.6vh'
            }}
        >
            <Grid2
                container
                size={12}
                justifyContent='space-between'
            >
                <Grid2 sx={{ color: darkTextColor, fontSize: '22px', lineHeight: '28px' }}>{contact?.name ?? '-'}</Grid2>
                {editMode ? <EditOutlined className='clickable' onClick={() => setOpenModal(true)} sx={{ color: darkTextColor, fontSize: '24px' }}/> : null}
            </Grid2>
            <Grid2
                container
                justifyContent='space-between'
                sx={{
                    rowGap: '1vh'
                }}
            >
                <Grid2 size={3.9} sx={labelStyle}>{i18n.type}</Grid2>
                <Grid2 size={7.9} sx={valueStyle}>{type ? type.name : i18n.unknown}</Grid2>
                <Grid2 size={3.9} sx={labelStyle}>{i18n.address}</Grid2>
                <Grid2 size={7.9} sx={valueStyle}>{getAdress()}</Grid2>
                <Grid2 size={3.9} sx={labelStyle}>{i18n.email}</Grid2>
                <Grid2 size={7.9} sx={valueStyle}>{contact?.email ?? '-'}</Grid2>
                <Grid2 size={3.9} sx={labelStyle}>{i18n.phoneTel}</Grid2>
                <Grid2 size={7.9} sx={valueStyle}>{formatPhone(contact?.phoneTel) ?? '-'}</Grid2>
                <Grid2 size={3.9} sx={labelStyle}>{i18n.phoneMobile}</Grid2>
                <Grid2 size={7.9} sx={valueStyle}>{formatPhone(contact?.mobile) ?? '-'}</Grid2>
            </Grid2>
            {openModal && <ModalContact
                isOpen={openModal}
                contact={contact}
                type={type}
                onClose={(() => setOpenModal(false))}
                onSave={onSave}
                onDelete={onDelete}
                showLegalRepresentative={showLegalRepresentative || type.code === -1}
                defaultLegalRepresentative={defaultLegalRepresentative}
            />}
        </LightCard>
    )
}

ContactCardDesktop.propTypes = {
    contact: instanceOf(DtoContact).isRequired,
    type: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.number,
    }),
    editMode: PropTypes.bool,
    showLegalRepresentative: PropTypes.bool,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    defaultLegalRepresentative: PropTypes.bool,
}

export default ContactCardDesktop
