import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import DtoTankDeclaration from '../../agri/dto/enquete/DtoTankDeclaration'
import DtoTankExploit from '../../agri/dto/exploitation/DtoTankExploit'
import { formatMilliers } from '../../../../utils/StringUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { LightCard } from '../styled/grid'
import { instanceOf } from '../../../../utils/StoreUtils'

const TankCard = ({ title, retenue, noLightCard, sx }) => {
    const {
        codesSandre,
        agriTanksTypes,
    } = useSelector(store => ({
        codesSandre: store.ReferencialReducer.codesSandre,
        agriTanksTypes: store.ReferencialReducer.agriTanksTypes,
    }), shallowEqual)

    const fillingModes = useMemo(() => codesSandre.filter((c) => c.field === 'PREL_AGRI.MODE_REMPLISSAGE'), [codesSandre])
    const fillingPeriodes = useMemo(() => codesSandre.filter((c) => c.field === 'PREL_AGRI.PERIODE_REMPLISSAGE'), [codesSandre])
    const tanksPeriodesDetails = useMemo(() => codesSandre.filter((code) => code.field === 'USAGES.PERIODES_DETAILS'), [codesSandre])

    const tankSaisonnal = useMemo(() => (retenue.link_periodes || []).find((p) => p.idPeriode === 2), [retenue])
    const tankAnnuel = useMemo(() => (retenue.link_periodes || []).find((p) => p.idPeriode === 1), [retenue])
    const tankUnknown = useMemo(() => (retenue.link_periodes || []).find((p) => p.idPeriode === 0), [retenue])
    const fillingPeriod = useMemo(() => tankSaisonnal ? 2 : (tankAnnuel ? 1 : (hasValue(tankUnknown) ? 0 : null)), [retenue, tankSaisonnal, tankAnnuel, tankUnknown])

    const tankType = useMemo(() => agriTanksTypes.find((type) => type.id === retenue.tankType), [retenue, agriTanksTypes])
    const fillingMode = useMemo(() => fillingModes.find((mode) => mode.code === retenue.fillingMode), [retenue, fillingModes])
    const fillingPeriode = useMemo(() => fillingPeriodes.find((c) => c.code === fillingPeriod) || {}, [fillingPeriod, fillingPeriodes])


    const getSelectedPeriod = (idPeriode, tankPeriodes) => {
        return !!tankPeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    let saisons = ''
    if (tankSaisonnal) {
        tanksPeriodesDetails.map((p) => {
            if (getSelectedPeriod(p.code, retenue.link_periodes)) {
                saisons = `${saisons}${p.name}, `
            }
        })
    }

    const content = (
        <Grid2 container size={12} direction='column' rowSpacing={1.5} sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }}>
            <Grid2 container size={12} columnSpacing={2} justifyContent='space-between'>
                <Grid2 size={'auto'}>{i18n.id}</Grid2>
                <Grid2 size={'grow'} sx={{ textAlign: 'end' }} className='bold'>{retenue?.id ?? '-'}</Grid2>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} justifyContent='space-between'>
                <Grid2 size={'auto'}>{i18n.type}</Grid2>
                <Grid2 size={'grow'} sx={{ textAlign: 'end' }} className='bold'>{tankType?.name ?? '-'}</Grid2>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} justifyContent='space-between'>
                <Grid2 size={'auto'} >{i18n.capacity}</Grid2>
                <Grid2 size={'grow'} sx={{ textAlign: 'end' }} className='bold'>{hasValue(retenue?.capacity) ? `${formatMilliers(retenue.capacity)} m3` : '-'}</Grid2>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} justifyContent='space-between'>
                <Grid2 size={'auto'}>{i18n.recoveryFlow}</Grid2>
                <Grid2 size={'grow'} sx={{ textAlign: 'end' }} className='bold'>{retenue?.recoveryFlow ?? '-'}</Grid2>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} justifyContent='space-between'>
                <Grid2 size={'auto'}>{i18n.reserveFlow}</Grid2>
                <Grid2 size={'grow'} sx={{ textAlign: 'end' }} className='bold'>{retenue?.reserveFlow ?? '-'}</Grid2>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} justifyContent='space-between'>
                <Grid2 size={'auto'}>{i18n.fillingMode}</Grid2>
                <Grid2 size={'grow'} sx={{ textAlign: 'end' }} className='bold'>{fillingMode?.name ?? '-'}</Grid2>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} justifyContent='space-between'>
                <Grid2 size={'auto'}>{i18n.fillingPeriod}</Grid2>
                <Grid2 size={'grow'} sx={{ textAlign: 'end' }} className='bold'>{fillingPeriode?.name ?? '-'}{!!saisons.length && ` (${saisons.slice(0, -2)})`}</Grid2>
            </Grid2>
            <Grid2 container size={12} columnSpacing={2} justifyContent='space-between'>
                <Grid2 size={'auto'}>{i18n.sharedRetention}</Grid2>
                <Grid2 size={'grow'} sx={{ textAlign: 'end' }} className='bold'>{retenue?.sharedRetention ? i18n.yes : i18n.no}</Grid2>
            </Grid2>
        </Grid2>
    )

    if (noLightCard) {
        return content
    }

    return (
        <LightCard
            container
            direction='column'
            justifyContent='space-between'
            className='clickable'
            sx={{ marginBottom: '20px', ...sx }}
        >
            <Grid2 fontSize={22} className='bold' sx={{ marginBottom: '20px' }}>{title ||i18n.retention}</Grid2>
            {content}
        </LightCard>
    )
}

TankCard.propTypes = {
    title: PropTypes.string,
    retenue: instanceOf(DtoTankDeclaration || DtoTankExploit).isRequired,
    noLightCard: PropTypes.bool,
    sx: PropTypes.shape({}),
}

export default TankCard
