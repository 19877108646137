import DtoMatChronicle from '../../../referencials/installations/dto/DtoMatChronicle'
import DtoDeclarationVolumes from './DtoDeclarationVolumes'

/* eslint-disable camelcase */
export default class DtoDeclaration {
    constructor(object) {
        this.idDeclaration = object.idDeclaration // Long
        this.idContributor = object.idContributor // Long
        this.idExploitation = object.idExploitation // Long
        this.statusCode = object.statusCode // Option[Int]
        this.updateDate = object.updateDate // Option[DateTime]
        this.updateLogin = object.updateLogin // Option[String]
        this.comment = object.comment // Option[String]
        this.lastStep = object.lastStep // Option[Int]
        this.idSurvey = object.idSurvey // Option[Long]

        this.link_declarationInstallation = object.link_declarationInstallation || [] // Option[Seq[SamplingPointExploitation]]
        this.link_exploitationInstallation = object.link_exploitationInstallation || [] // Option[Seq[ExploitationSamplingPoint]] = None
        this.link_chronicles = object.link_chronicles ? object.link_chronicles.map((c) => new DtoMatChronicle(c)) : [] // Option[Seq[MaterielChronicle]] = None
        this.link_pumps = object.link_pumps || [] // Option[Seq[UpdatedPump]] = None,
        this.link_counters = object.link_counters || [] // Option[Seq[UpdatedCounter]] = None
        this.link_contributorTypes = object.link_contributorTypes || [] // Option[Seq[UpdatedContributorType]] = None
        this.link_exploitationMateriel = object.link_exploitationMateriel || [] // Option[Seq[VariousSituation]] = None
        this.link_volumes = object.link_volumes ? object.link_volumes.map((v) => new DtoDeclarationVolumes(v)) : [] // Option[Seq[DeclarationVolumes]] = None
    }
}
