import React from 'react'
import { CircularProgress, Grid2 } from '@mui/material'
import { mainColor } from '../styled/theme'
import PropTypes from 'prop-types'

const LoadingCard = ({ sx, progress, ...otherProps }) => {
    return (
        <Grid2 container size={12} sx={{ height: '100%', ...sx }} alignItems='center' justifyContent='center' { ...otherProps }>
            <CircularProgress variant={progress ? 'determinate' : 'indeterminate'} value={progress} sx={{ color: mainColor }} { ...otherProps }/>
        </Grid2>
    )
}

LoadingCard.propTypes = {
    sx: PropTypes.shape({}),
    progress: PropTypes.number,
}

export default LoadingCard