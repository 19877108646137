import moment from 'moment'
import i18n from 'simple-react-i18n'

export default class DtoArrest {
    constructor(obj = {}) {
        this.id = obj.arrestNumber
        this.beginDate = obj.beginDate
        this.signatureDate = obj.signatureDate
        this.endDate = obj.endDate
        this.comment = this.getTitle(obj)
        this.title = obj.arrestNumber
        this.alerteZone = obj.departmentCode
        this.typeArrest = obj.maxRestriction
        this.level = obj.maxLevel
        this.departmentCode = obj.departmentCode
        this.departmentLabel = obj.departmentLabel
    }

    dayFormater = timestamp => moment(timestamp).format('DD/MM/YYYY')

    getTitle = ({
        beginDate,
        endDate,
    }) => {
        const begin = this.dayFormater(beginDate)
        const end = this.dayFormater(endDate)
        return `${i18n.waterRestrictionOrder} ${i18n.fromDate} ${begin} ${i18n.to} ${end}`
    }
}