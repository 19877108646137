import moment from 'moment'
import 'moment/locale/fr'
import { capitalizeFirstLetter } from './StringUtil'
import { isNull, isUndefined } from 'lodash'

moment.locale('fr')
const formatDate = (timestamp) => {
    if (timestamp) {
        return new Date(timestamp).toLocaleDateString()
    }
    return ''
}

const getDate = (timestamp, format = 'DD/MM/YYYY') => {
    if (isUndefined(timestamp) || isNull(timestamp)) {
        return ''
    }
    return moment(timestamp).format(format)
}

const getFullDate = timestamp => getDate(timestamp, 'DD/MM/YYYY HH:mm:ss')

const getDateAndHour = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY - HH:mm')
    }
    return ''
}

const getHourAndMinute = (hour) => {
    if (hour) {
        return moment(hour).format('HH:mm')
    }
    return ''
}
const getMiniDate = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YY')
    }
    return ''
}
const getMiniDateNoYear = (date) => {
    if (date) {
        return moment(date).format('DD/MM')
    }
    return ''
}

const getDay = (dayNumber) => {
    return capitalizeFirstLetter(moment().isoWeekday(dayNumber).format('dddd'))
}

export {
    formatDate,
    getDate,
    getMiniDate,
    getMiniDateNoYear,
    getFullDate,
    getDateAndHour,
    getHourAndMinute,
    getDay,
}
