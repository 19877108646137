'use strict'
import i18n from 'simple-react-i18n'
import {
    CITIES,
} from '../constants/CityConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { aquaFetchV2, getAuthorization } from '../../../../../utils/ActionUtils'
import ToastrAction from '../../../components/toasters/ToastrAction'
import LogAction from '../../../../../utils/log/actions/LogAction'

const CityAction = {
    receiveCities(cities) {
        return { type: CITIES, cities }
    },
    fetchCities() {
        return (dispatch, getState) => {
            return aquaFetchV2(ApplicationConf.referencial.cities(), {
                method: 'GET',
                headers: getAuthorization(),
            }, { defaultResult: getState().CityReducer.cities })
                .then((json = []) => {
                    dispatch(CityAction.receiveCities(json))
                })
                .catch((err) => {
                    LogAction.logError(`${i18n.fetchError}${i18n.cities} : ${err}`)
                    ToastrAction.error(`${i18n.fetchError}${i18n.cities}`)
                })
        }
    },
}

export default CityAction
