/* eslint-disable camelcase */
export default class DtoDeclarationWithStats {
    constructor(obj) {
        this.idDeclaration = obj.idDeclaration // Long,
        this.idContributor = obj.idContributor // Long,
        this.statusCode = obj.statusCode // Option[Int],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.comment = obj.comment // Option[String],
        this.lastStep = obj.lastStep // Option[Int],
        this.idSurvey = obj.idSurvey // Option[Long],
        this.idExploitation = obj.idExploitation // Option[Long],

        this.realVolume = obj.realVolume // Option[Double],
        this.prevVolume = obj.prevVolume // Option[Double],
        this.nbPtReal = obj.nbPtReal // Option[Long], // usages
        this.nbPtPrevi = obj.nbPtPrevi // Option[Long], // usages
        this.nbPts = obj.nbPts // Option[Long],
        this.year = obj.year // Option[Int] = None,
        this.surveyName = obj.surveyName // Option[String] = None,
        this.surveyType = obj.surveyType // Option[Int] = None,
        this.link_volumes = obj.link_volumes // Option[Seq[DeclarationVolumes]] = None,
        this.eligibleTankVolume = obj.eligibleTankVolume // Option[Double] = None
    }
}
