/* Convert a hex string to an RGB triplet */
const convertToRGB = (hex) => {
    const hexPart = hex.charAt(0) === '#' ? hex.substring(1, 7) : hex
    const formattedHex = hexPart.length === 3 ? `${hexPart[0]}${hexPart[0]}${hexPart[1]}${hexPart[1]}${hexPart[2]}${hexPart[2]}` : hexPart
    return [
        parseInt(formattedHex.substring(0, 2), 16),
        parseInt(formattedHex.substring(2, 4), 16),
        parseInt(formattedHex.substring(4, 6), 16),
    ]
}

const getDarkerColor = (color) => {
    const rgb = convertToRGB(color)
    return `rgb(${rgb[0] - 70},${rgb[1] - 70},${rgb[2] - 70})`
}

export { convertToRGB, getDarkerColor }