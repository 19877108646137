import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Grid2, useTheme } from '@mui/material'
import CmsAction from './online/cms/actions/CmsAction'
import LoadingCard from './online/components/cards/LoadingCard'
import { textColor } from './online/components/styled/theme'
import { LightCard } from './online/components/styled/grid'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { sortBy } from 'lodash'

const AboutDesktop = () => {
    const [cmsLoaded, setCmsLoaded] = useState(false)

    const {
        cmsEvents,
    } = useSelector(store => ({
        cmsEvents: store.CmsReducer.cmsEvents,
    }), shallowEqual)

    const dispatch = useDispatch()

    const theme = useTheme()

    useEffect(() => {
        if (!cmsEvents.length) {
            dispatch(CmsAction.fetchCMSEvents()).then(() => {
                setCmsLoaded(true)
            })
        } else {
            setCmsLoaded(true)
        }
    }, [])

    const showCMS = (category) => {
        const filteredCms = sortBy(cmsEvents.filter((cms) => cms.idCategory === category), ['id'])

        return filteredCms.map((cms) => {
            return (
                <LightCard key={cms.id} item size={12}>
                    <Grid2 fontSize={22} sx={{ paddingBottom: '8px' }}>{cms.title}</Grid2>
                    <div dangerouslySetInnerHTML={{ __html: cms.comment || '' }} />
                </LightCard>
            )
        })
    }

    if (!cmsLoaded) {
        return <LoadingCard />
    }

    return (
        <Grid2
            container
            size={12}
            sx={{
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <Grid2 container alignItems='center' justifyContent='flex-start' color={textColor}>
                <Grid2
                    sx={{ paddingRight: theme.spacing(3) }}
                    className='clickable'
                    onClick={() => window.history.back()}
                >
                    <KeyboardArrowLeft sx={{ fontSize: '30px' }}/>
                </Grid2>
                <Grid2 sx={{ fontSize: '22px', lineHeight: '28px' }} >{i18n.cgu}</Grid2>
            </Grid2>
            <Grid2
                container
                rowGap={theme.spacing(2)}
                sx={{
                    maxHeight: 'calc(100% - 3rem)',
                    overflowY: 'auto',
                    marginTop: theme.spacing(2)
                }}
            >
                {showCMS(1)}
                {showCMS(3)}
            </Grid2>
        </Grid2>

    )
}

export default AboutDesktop
