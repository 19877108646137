/* eslint-disable camelcase */
import moment from 'moment'
import DtoMatChronicle from '../../../referencials/installations/dto/DtoMatChronicle'
import DtoExploitationMatRep from './DtoExploitationMatRep'

export default class DtoExploitation {
    constructor(object) {
        this.idExploitation = object.idExploitation // Long
        this.codification = object.codification // Long
        this.structureType = object.structureType // Option[Long]
        this.status = object.status // Option[String]
        this.startDate = object.startDate // Option[DateTime]
        this.exploitationUpdateDate = object.exploitationUpdateDate // Option[DateTime]
        this.author = object.author // Option[String]
        this.comment = object.comment // Option[String]
        this.operatorCode = object.operatorCode // Option[Long]
        this.updateDate = object.updateDate // Option[DateTime]
        this.updateLogin = object.updateLogin // Option[String]
        this.cities = object.cities // Option[Seq[String]],

        this.link_samplings = object.link_samplings ? object.link_samplings.filter((p) => !p.endDate || p.endDate > moment().valueOf()) : [] // Option[Seq[SamplingPointExploitation]]
        this.link_samplingsHisto = object.link_samplings ? object.link_samplings.filter((p) => p.endDate <= moment().valueOf()) : [] // Option[Seq[SamplingPointExploitation]]
        this.link_contributors = object.link_contributors || [] // Option[Seq[ExploitationContributor]]
        this.link_chronicles = object.link_chronicles ? object.link_chronicles.map((c) => new DtoMatChronicle(c)) : [] // Option[Seq[MaterielChronicle]] = None
        this.link_materiel = object.link_materiel || []
        this.link_repartition_materiels = object.link_repartition_materiels ? object.link_repartition_materiels.map((r) => new DtoExploitationMatRep(r)) : []
        this.link_conso_real = object.link_conso_real || [] // Option[Seq[ExploitationConsoRealLink]] = None // only the last 10 years
    }
}
