import {
    DOCUMENT,
    PICTURE,
} from '../pages/online/referencials/documents/constants/FileConstants'
import { SANDRE } from '../pages/online/referencials/constants/ReferencialConstants'

const getFileNatureLabel = (fileName, sandreCodes) => {
    const [, type] = fileName.substr(0, fileName.lastIndexOf('.')).split('_')
    const fileNature = sandreCodes.some(s => s.name === type) ? type : 'INCONNU'
    return fileNature
}

const getTypeSandreCode = (type) => {
    if (type === PICTURE) {
        return SANDRE.TYPE_PHOTO
    } else if (type === DOCUMENT) {
        return SANDRE.TYPE_DOCUMENT
    } return SANDRE.TYPE_PHOTO
}

export { getFileNatureLabel, getTypeSandreCode }
